import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'components/auth'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { AllMembershipsType } from 'types'
import { makeMemberPortalPath, PATH, USER_METADATA } from 'config'
import { withApiAuth } from 'utils'

const propTypes = {
  allMemberships: AllMembershipsType,
  children: PropTypes.node.isRequired,
  clearAllMemberships: PropTypes.func.isRequired,
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
}

function AllMembershipsProvider({
  allMemberships,
  children,
  clearAllMemberships,
  fetchAllMembershipsForAccount,
}) {
  const history = useHistory()

  const { user } = useAuth()
  const memberAccountId = user[USER_METADATA.MEMBER_ACCOUNT_ID]

  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        await fetchAllMembershipsForAccount(memberAccountId)
      } catch (error) {
        history.push(makeMemberPortalPath(PATH.MEMBER_APPLICATION))
      }
    }
    fetchMemberships()

    return () => clearAllMemberships()
  }, [memberAccountId])

  if (!allMemberships) return <Spinner />

  return children
}

AllMembershipsProvider.propTypes = propTypes

function mapStateToProps(state) {
  return {
    allMemberships: selectors.allMemberships(state),
  }
}

const mapDispatchToProps = {
  clearAllMemberships: actions.clearAllMemberships,
}

const mapApiAuthToProps = {
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps),
)(AllMembershipsProvider)
