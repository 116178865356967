const getPrimaryMembership = (allMemberships) => {
  const membership = allMemberships?.memberships?.find(
    ({ primary_membership__c }) => primary_membership__c,
  )

  if (membership) {
    const { membership_status__c: membershipStatus, sfid } = membership
    return { membershipStatus, sfid }
  }

  return {}
}

export default getPrimaryMembership
