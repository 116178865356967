import { useEffect, useState } from 'react'
import { login as customLogin } from 'components/auth'
import { api } from 'api'
import { API_ERROR_MAP } from 'types'
import { makeLoginPath, PATH } from 'config'

const useSeatowService = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true)
      try {
        await api.get(makeLoginPath(PATH.SESSION, PATH.COOKIE))
        const { claims } = await api.get(
          makeLoginPath(PATH.SESSION, PATH.AUTHORIZATION),
        )
        setIsAuthenticated(true)
        setUser(claims)
      } catch (e) {
        if (e.message === API_ERROR_MAP.NO_COOKIE_FOUND) return
        logout({ logoutParams: { returnTo: window.location.pathname } })
      } finally {
        setIsLoading(false)
      }
    }

    checkAuth()
  }, [])

  const login = async (params) => {
    await customLogin(params)
  }

  const logout = async ({ logoutParams: { returnTo } }) => {
    await api.get(makeLoginPath(PATH.SESSION, PATH.LOGOUT))
    window.location.href = returnTo
  }

  const getAccessTokenSilently = async () => {
    const { access_token } = await api.get(
      makeLoginPath(PATH.SESSION, PATH.AUTHORIZATION),
    )
    return access_token
  }

  return {
    isAuthenticated,
    user,
    isLoading,
    login,
    logout,
    setIsAuthenticated,
    setUser,
    getAccessTokenSilently,
  }
}

export default useSeatowService
