/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createStubRequest } from 'lp-redux-api'
import * as Fixtures from 'fixtures'

// [ Account ]---------------------------------------------------------------
export const createContact = createStubRequest('CREATE_CONTACT', (contact) =>
  console.log(
    '[NOTE]: contact will be created with: ',
    JSON.stringify(contact, null, 2)
  )
)

export const deleteContact = createStubRequest('DELETE_CONTACT', (contact) =>
  console.log(
    '[NOTE]: contact to be deleted: ',
    JSON.stringify(contact, null, 2)
  )
)

export const fetchAccount = createStubRequest('FETCH_ACCOUNT', () => {
  // return Fixtures.fetchAccount
  return Fixtures.fetchPartnerAccount
})

export const fetchContacts = createStubRequest(
  'FETCH_CONTACTS',
  () => Fixtures.fetchContacts
)

export const requestPasswordChange = createStubRequest(
  'REQUEST_PASSWORD_CHANGE',
  (user) => {
    console.log(
      '[NOTE]: password change request will be initiated for: ',
      JSON.stringify(user, null, 2)
    )

    return Fixtures.requestPasswordChangeSuccess
  }
)

export const updateAccount = createStubRequest('UPDATE_ACCOUNT', (account) =>
  console.log(
    '[NOTE]: account will be updated with: ',
    JSON.stringify(account, null, 2)
  )
)

export const updateContact = createStubRequest('UPDATE_CONTACT', (contact) =>
  console.log(
    '[NOTE]: contact will be updated with: ',
    JSON.stringify(contact, null, 2)
  )
)

export const uploadLogo = createStubRequest('UPLOAD_LOGO', (logo) =>
  console.log(
    '[NOTE]: logo upload will be initiated with: ',
    JSON.stringify(logo, null, 2)
  )
)

// [ AOR ]------------------------------------------------------------------
export const fetchAor = createStubRequest('FETCH_AOR', (latLngCoordinates) =>
  console.log(
    '[NOTE]: will request aor information with: ',
    JSON.stringify(latLngCoordinates, null, 2)
  )
)

// [ ARB ]------------------------------------------------------------------
export const prepareOpportunityAndOLIs = createStubRequest(
  'PREPARE_OPPORTUNITY_AND_OLIS',
  (billing) =>
    console.log(
      '[NOTE]: will prepare payment opportunity and return opp_guid ',
      JSON.stringify(billing, null, 2)
    )
)

export const addPaymentToOpportunity = createStubRequest(
  'ADD_PAYMENT_TO_OPPORTUNITY',
  (billing) =>
    console.log(
      '[NOTE]: will post payment with the provided opp_guid and billing data ',
      JSON.stringify(billing, null, 2)
    )
)

export const enrollAutoRenewal = createStubRequest(
  'ENROLL_AUTO_RENEWAL',
  (autoRenewal) =>
    console.log(
      '[NOTE]: will request auto-renewal enrollment with: ',
      JSON.stringify(autoRenewal, null, 2)
    )
)

export const updateARBCreditCard = createStubRequest(
  'UPDATE_ARB_CREDIT_CARD',
  (creditCard) =>
    console.log(
      '[NOTE]: will update ARB credit card to: ',
      JSON.stringify(creditCard, null, 2)
    )
)

export const unenrollAutoRenewal = createStubRequest(
  'UNENROLL_AUTO_RENEWAL',
  (autoRenewal) =>
    console.log(
      '[NOTE]: will request auto-renewal unenrollment with: ',
      JSON.stringify(autoRenewal, null, 2)
    )
)

// [Authentication/Authorization]-------------------------------------------
export const verifyMembership = createStubRequest(
  'VERIFY_MEMBERSHIP',
  (membership) => {
    console.log(
      '[NOTE]: will verify membership with: ',
      JSON.stringify(membership, null, 2)
    )
  }
)

// [Billing]----------------------------------------------------------------
export const updateBillingAddress = createStubRequest(
  'UPDATE_BILLING_ADDRESS',
  (billingAddress) =>
    console.log(
      '[NOTE]: will update billing address to: ',
      JSON.stringify(billingAddress, null, 2)
    )
)

// [ Boats ]--------------------------------------------------------------

export const createBoat = createStubRequest('Create_BOAT', (boat) =>
  console.log('[NOTE]: will create  boat to: ', JSON.stringify(boat, null, 2))
)

export const updateBoat = createStubRequest('UPDATE_BOAT', (boat) =>
  console.log('[NOTE]: will update boat to: ', JSON.stringify(boat, null, 2))
)

export const setOldInactiveAndSetNewPrimary = createStubRequest(
  'CHANGE_PRIMARY',
  (oldBoat, newBoat) => {
    oldBoat.primary_boat__c = false
    oldBoat.boat_status__c = 'Inactive'
    newBoat.primary_boat__c = true
    newBoat.boat_status__c = 'Active'
    console.log(
      '[NOTE]: will update OLD Primary Boat to: ',
      JSON.stringify(oldBoat, null, 2),
      '\nAnd the NEW Primary Boat to: ',
      JSON.stringify(newBoat, null, 2)
    )
  }
)

// [ Gated Content ]------------------------------------------------------
export const fetchVideoDetails = createStubRequest(
  'FETCH_VIDEO_DETAILS',
  (videoId) => Fixtures.privateVideoDetails,
)

// [ Membership Card ]------------------------------------------------------
export const fetchMembershipCardOrderStatus = createStubRequest(
  'FETCH_MEMBERSHIP_CARD_ORDER_STATUS',
  (membershipId) => Fixtures.fetchMembershipFulfillments,
  { delay: 1000 }
)

export const requestCardReprint = createStubRequest(
  'REQUEST_CARD_REPRINT',
  (membership) =>
    console.log(
      ('[NOTE]: will send card reprint request with: ',
        JSON.stringify(membership, null, 2))
    )
)

// [ Memberships ]----------------------------------------------------------
export const fetchAllMembershipsForAccount = createStubRequest(
  'FETCH_ALL_MEMBERSHIPS_FOR_ACCOUNT',
  (accountId) => {
    return Fixtures.fetchAllMembershipsForAccountActiveEnrolled
    // return Fixtures.fetchAllMembershipsForAccountMultipleMemberships
    // return Fixtures.fetchAllMembershipsForAccountActiveNotEnrolled
    // return Fixtures.fetchAllMembershipsForAccountActiveSubsidized

    // For this scenario, make sure the member expiration date in the fixture
    // is within 45 days of the membership expiration date.
    // return Fixtures.fetchAllMembershipsForAccountActiveNotEnrolledExpiring

    // For this scenario, make sure the membership expiration date in the fixture
    // is after the current date.
    // return Fixtures.fetchAllMembershipsForAccountExpired

    // return Fixtures.fetchAllMembershipsForAccountCancelled
    // return Fixtures.fetchAllMembershipsForAccountInactive

    // For these scenarios, make sure that the membership effective date in the
    // fixture is later than the current date/time.
    // return Fixtures.fetchAllMembershipsForAccountFutureEnrolled
    // return Fixtures.fetchAllMembershipsForAccountFutureNotEnrolled

    // Trailer care scenarios...
    // return Fixtures.fetchAllMembershipsForAccountActiveTrailerCareUnavailable
    // return Fixtures.fetchAllMembershipsForAccountActiveTrailerCareSingleYearNotEnrolled
    // return Fixtures.fetchAllMembershipsForAccountActiveTrailerCareMultiYearNotEnrolled
    // return Fixtures.fetchAllMembershipsForAccountExpiredTrailerCareSingleYearNotEnrolled
    // return Fixtures.fetchAllMembershipsForAccountExpiredTrailerCareMultiYearNotEnrolled
    // return Fixtures.fetchAllMembershipsForAccountActiveTrailerCareSingleYearBasic
    // return Fixtures.fetchAllMembershipsForAccountActiveTrailerCareMultiYearBasic
    // return Fixtures.fetchAllMembershipsForAccountExpiredTrailerCareSingleYearBasic
    // return Fixtures.fetchAllMembershipsForAccountExpiredTrailerCareMultiYearBasic
    // return Fixtures.fetchAllMembershipsForAccountActiveTrailerCareSingleYearPlus
    // return Fixtures.fetchAllMembershipsForAccountActiveTrailerCareMultiYearPlus
    // return Fixtures.fetchAllMembershipsForAccountExpiredTrailerCareSingleYearPlus
    // return Fixtures.fetchAllMembershipsForAccountExpiredTrailerCareMultiYearPlus
  }
)

export const fetchMembershipAndAor = createStubRequest(
  'FETCH_MEMBERSHIP_AND_AOR',
  (membershipId) => {
    console.log('FETCHING MEMBERSHIP AND AOR for: ', membershipId)
    return Fixtures.fetchMembershipAndAorSuccess
  },
  { delay: 1000 }
)

// [ Membership Kit ]--------------------------------------------------------
export const applyForMembershipKit = createStubRequest(
  'APPLY_FOR_MEMBERSHIP_KIT',
  (contact) => {
    console.log(
      '[NOTE]: will send the following membership kit contact data',
      JSON.stringify(contact, null, 2)
    )
  }
)

// [ Membership Upgrades ]---------------------------------------------------
export const chargeMembershipUpgrade = createStubRequest(
  'CHARGE_MEMBERSHIP_UPGRADE',
  (upgrade) =>
    console.log(
      '[NOTE]: will validate and post membership upgrade with: ',
      JSON.stringify(upgrade, null, 2)
    )
)

// [ New Member Application ]-------------------------------------------------
export const createMembershipApplicationAccount = createStubRequest(
  'CREATE_MEMBERSHIP_APPLICATION_ACCOUNT',
  ({ account, contact, membership }) => {
    console.log(
      '[NOTE]: will create a new account, contact and membership with: ',
      JSON.stringify({ account, contact, membership }, null, 2)
    )
  }
)

export const fetchActivationCodeDetails = createStubRequest(
  'FETCH_ACTIVATION_CODE_DETAILS',
  (activationCode) => Fixtures.activationCodeDetails
)

export const fetchMembershipApplicationAccount = createStubRequest(
  'FETCH_MEMBERSHIP_APPLICATION_ACCOUNT',
  (accountId, membershipId) => Fixtures.membershipApplicationAccount
)

export const fetchProductPrices = createStubRequest(
  'FETCH_PRODUCT_PRICES',
  () => Fixtures.productPrices
)

export const fetchPromoCodeDetails = createStubRequest(
  'FETCH_PROMO_CODE_DETAILS',
  ({ promoCode, isRenewal, membershipStatus }) => Fixtures.promoCodeDetails
)

export const updateMembershipApplicationAccount = createStubRequest(
  'UPDATE_MEMBERSHIP_APPLICATION_ACCOUNT',
  ({ account, contact, membership }) => {
    console.log(
      `[NOTE]: will update the existing account, contact and membership with: `,
      JSON.stringify({ account, contact, membership }, null, 2)
    )

    return Fixtures.membershipApplicationAccount
  }
)

export const updateMembershipAssociatedAccount = createStubRequest(
  'UPDATE_MEMBERSHIP_ASSOCIATED_ACCOUNT',
  ({ email, memberNumber }) => {
    console.log(
      `[NOTE]: will reauthorize an account through auth0: `,
      JSON.stringify({ email, memberNumber }, null, 2)
    )

    return Fixtures.membershipApplicationAccount
  }
)

export const verifyUniquePrimaryEmail = createStubRequest(
  'VERIFY_UNIQUE_PRIMARY_EMAIL',
  (email) => ({ contact_exists: false })
)

// [ Programs Participant Application ]------------------------------------------------
export const applyAsAParticipant = createStubRequest(
  'APPLY_AS_NEW_PARTNER',
  (participant) => {
    console.log(
      '[NOTE]: will apply as a new participant with: ',
      JSON.stringify(participant, null, 2)
    )

    // return Fixtures.applyAsNewPartnerAuth0Account
    return Fixtures.applyAsNewPartnerNoAuth0Account
    // return Fixtures.applyAsNewPartnerNoAuth0Check
  }
)

export const createAndLinkAccount = createStubRequest(
  'CREATE_AND_LINK_ACCOUNT',
  (account) => {
    console.log(
      '[NOTE]: will create partner account with: ',
      JSON.stringify(account, null, 2)
    )
    throw new Error('DUPLICATE_TRANSACTION_SUBMITTED')
  }
)

// [ Offers ]----------------------------------------------------------------
export const createOffer = createStubRequest('CREATE_OFFER', (offer) => {
  console.log(
    '[NOTE]: will create offer with: ',
    JSON.stringify(offer, null, 2)
  )
})

export const fetchRandomOffers = createStubRequest(
  'FETCH_RANDOM_OFFERS',
  () => {
    return Fixtures.fetchRandomOffers
  }
)

export const swapMetadata = createStubRequest('LINK_ACCOUNT', (account) => {
  console.log(
    '[NOTE]: will link account with: ',
    JSON.stringify(account, null, 2)
  )
})

export const updateOffer = createStubRequest('UPDATE_OFFER', (offer) => {
  console.log('[NOTE]: will update offer to: ', JSON.stringify(offer, null, 2))
})

// [ Programs ]-------------------------------------------------------
export const fetchAllProgramsForPartner = createStubRequest(
  'FETCH_ALL_PROGRAMS_FOR_PARTNER',
  (accountId) => {
    return Fixtures.fetchAllProgramsForPartner
  }
)

// [ Referrals ]-------------------------------------------------------------
export const createReferral = createStubRequest('CREATE_REFERRAL', (referral) =>
  console.log(
    '[NOTE]: will create referral with: ',
    JSON.stringify(referral, null, 2)
  )
)

export const fetchReferral = createStubRequest('FETCH_REFERRAL', (referral) => {
  return Fixtures.fetchReferralForNewMember
}
)

// [ Savings Club ]-----------------------------------------------------------
export const fetchOfferDetail = createStubRequest(
  'FETCH_OFFER_DETAIL',
  (offerId) => {
    console.log('[NOTE]: fetching offer detail for offer ID: ', offerId)

    return Fixtures.fetchOfferDetail
  }
)

export const searchSavingsClub = createStubRequest(
  'SEARCH_SAVINGS_CLUB',
  (search) => {
    console.log(
      '[NOTE]: will perform Savings Club search with: ',
      JSON.stringify(search, null, 2)
    )

    return Fixtures.featuredOffers
  },
  { delay: 2000 }
)

// [ Sea Care ]-------------------------------------------------------------
export const applyForSeaCare = createStubRequest(
  'APPLY_FOR_SEA_CARE',
  (contact) => {
    console.log(
      '[NOTE]: will send the following Sea Care contact data',
      JSON.stringify(contact, null, 2)
    )
  }
)

// [ Sea Tow as a Gift ]----------------------------------------------------
export const sendSeaTowGift = createStubRequest(
  'SEND_SEA_TOW_GIFT',
  (gift) => {
    console.log(
      '[NOTE]: will send the following to Sea Tow as a gift data',
      JSON.stringify(gift, null, 2)
    )

    return {}
  },
  { delay: 2000 }
)

// [ Trailer Care Addition ]-------------------------------------------------
export const addTrailerCare = createStubRequest(
  'ADD_TRAILER_CARE',
  (trailerCare) => {
    console.log(
      '[NOTE]: will send the following trailer care addition data',
      JSON.stringify(trailerCare, null, 2)
    )

    return {}
  }
)