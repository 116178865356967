import React from 'react'
import PropTypes from 'prop-types'
import useAuth from 'components/auth/useAuth'
import { Button } from 'lp-components'
import { EditPasswordChangeForm } from '../forms'
import { LOGIN_STRATEGY } from 'types'
import { REACT_APP_SEATOW_AUTH0_CLIENTID, USER_METADATA } from 'config'
import { useToggle } from 'utils'

const propTypes = {
  requestPasswordChange: PropTypes.func.isRequired,
}

function PasswordAndUsername({
  requestPasswordChange,
}) {
  const { user } = useAuth()
  const loginStrategy = user[USER_METADATA.STRATEGY]

  return (
    <div className="card">
      <header>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Password.svg"
          alt=""
          className="icon"
        />
        <h2>Username & Password</h2>
      </header>
      <div className="card-inner">
        {loginStrategy === LOGIN_STRATEGY.USERNAME_PASSWORD ? (
          <UsernamePasswordStrategy
            requestPasswordChange={requestPasswordChange}
          />
        ) : (
          <SocialStrategy />
        )}
      </div>
    </div>
  )
}

PasswordAndUsername.propTypes = propTypes

export default PasswordAndUsername

function UsernamePasswordStrategy({ requestPasswordChange }) {
  const { logout, user } = useAuth()
  const { email, sub: auth0Userid } = user
  const [editPasswordFormOpen, toggleEditPasswordForm] = useToggle()

  if (editPasswordFormOpen)
    return (
      <EditPasswordChangeForm
        onSubmit={() => {
          return requestPasswordChange({
            client_id: REACT_APP_SEATOW_AUTH0_CLIENTID,
            user_id: auth0Userid,
          })
        }}
        onSubmitSuccess={({ ticket: passwordChangeUrl }) => {
          logout({
            logoutParams: {
              returnTo: passwordChangeUrl,
            },
          })
        }}
        toggleEditPasswordForm={toggleEditPasswordForm}
      />
    )

  return (
    <div className="row">
      <div className="col-6">
        <h3>Username</h3>
        <p>{email}</p>
      </div>
      <div className="col-6">
        <h3>Password</h3>
        <Button onClick={toggleEditPasswordForm} className="link-primary">
          (Edit)
        </Button>
        <p>
          <em>{'Hidden'}</em>
        </p>
      </div>
    </div>
  )
}

function SocialStrategy() {
  return (
    <p>
      Manage your username and password directly with your social identity
      provider.
    </p>
  )
}
