import { useAuth0 } from '@auth0/auth0-react'

const useAuth0Service = () => {
  const {
    isAuthenticated,
    user,
    isLoading,
    error,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0()

  return {
    isAuthenticated,
    user,
    isLoading,
    error,
    login: (params) => {
      loginWithRedirect(params)
    },
    logout: (returnTo) => logout(returnTo),
    getAccessTokenSilently,
  }
}

export default useAuth0Service
