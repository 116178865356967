import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import useAuth from 'components/auth/useAuth'
import { Button, Spinner } from 'lp-components'
import { ButtonLink, RedirectWithQueryParams } from 'components'
import { AllMembershipsType } from 'types'
import { selectors } from 'memberships/reducer'
import { isEmpty } from 'lodash'
import * as apiActions from 'api-actions'
import {
  PATH,
  USER_METADATA,
  makePath,
  makeMemberApplicationPath,
  makeMembershipsPath,
  makeMarketingSitePath,
} from 'config'
import { withApiAuth } from 'utils'

const propTypes = {
  allMemberships: AllMembershipsType,
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
}

function NewOrRenew({ allMemberships, fetchAllMembershipsForAccount }) {
  const [isLoading, setIsLoading] = useState(true)
  const { isAuthenticated, logout, user } = useAuth()

  const memberAccountId = user?.[USER_METADATA.MEMBER_ACCOUNT_ID] ?? null
  const hasMemberAccountId = isAuthenticated && memberAccountId
  const isUnboundMember = !allMemberships || isEmpty(allMemberships.memberships)

  const logoutAndReturnToApp = () =>
    logout({
      logoutParams: { returnTo: makeMarketingSitePath(PATH.SEATOW_LOGOUT) },
    })

  useEffect(() => {
    const fetchMemberships = async () => {
      setIsLoading(true)
      await fetchAllMembershipsForAccount(memberAccountId)
      setIsLoading(false)
    }

    if (memberAccountId) {
      fetchMemberships()
    }
  }, [])

  // Verify that the logged-in user has access to the member portal. If they
  // already have an account but their membership is unbound (no SF data),
  // let them know to sign up for a new membership. If they have an account
  // and are not unbound, let them know they already have a membership that
  // exists. Otherwise send them directly to sign up for a membership.
  if (isLoading && hasMemberAccountId) return <Spinner />

  if (hasMemberAccountId && isUnboundMember) {
    return (
      <UnfoundMembershipDialog logoutAndReturnToApp={logoutAndReturnToApp} />
    )
  }

  if (hasMemberAccountId && !isUnboundMember) {
    return <ExistingMembershipDialog />
  }

  return (
    <RedirectWithQueryParams
      to={makeMemberApplicationPath(PATH.NEW_MEMBER_QUESTIONNAIRE)}
    />
  )
}

NewOrRenew.propTypes = propTypes

function mapStateToProps(state) {
  return { allMemberships: selectors.allMemberships(state) }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps),
)(NewOrRenew)

function ExistingMembershipDialog() {
  return (
    <section className="form-block-container">
      <div className="card card-medium">
        <div className="card-inner">
          <div className="group-block">
            <h3 className="group-title">Existing Member</h3>
            <p>
              We see that you already have or had a Sea Tow membership. Would
              you like to renew your existing membership? If you would like to
              add a new membership to your existing account, please{' '}
              <a href={makePath(PATH.SEATOW, PATH.CONTACT)}>contact Sea Tow</a>{' '}
              for assistance.
            </p>
            <div className="button-group">
              <Button
                className="button-primary-outline"
                onClick={() => window.location.replace(makePath(PATH.SEATOW))}
              >
                Cancel
              </Button>
              <ButtonLink
                className="button-primary"
                to={makeMembershipsPath(
                  PATH.SELECT_YOUR_MEMBERSHIP,
                  PATH.RENEW_MEMBERSHIP,
                )}
              >
                Renew Membership
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function UnfoundMembershipDialog({ logoutAndReturnToApp }) {
  return (
    <section className="form-block-container">
      <div className="card card-medium">
        <div className="card-inner">
          <div className="group-block">
            <h3 className="group-title">No Membership Found</h3>
            <p>
              The page you are trying to view is for members only. Continue to
              join now and start enjoying the benefits of a Sea Tow Membership.
            </p>
            <div className="button-group">
              <Button
                className="button-primary-outline"
                onClick={logoutAndReturnToApp}
              >
                Cancel
              </Button>
              <ButtonLink
                className="button-primary"
                to={makeMemberApplicationPath(PATH.NEW_MEMBER_QUESTIONNAIRE)}
              >
                Continue
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
