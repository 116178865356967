const AUTH_NONCE_KEY = 'auth_nonce'
const LOGIN_EXPERIENCE_KEY = 'login_experience'

export const getAuthNonce = () =>
  JSON.parse(localStorage.getItem(AUTH_NONCE_KEY))

export const setAuthNonce = (nonce) =>
  localStorage.setItem(AUTH_NONCE_KEY, JSON.stringify(nonce))

export const removeAuthNonce = () => localStorage.removeItem(AUTH_NONCE_KEY)

export const getLoginExperience = () =>
  localStorage.getItem(LOGIN_EXPERIENCE_KEY)

export const setLoginExperience = (type) =>
  localStorage.setItem(LOGIN_EXPERIENCE_KEY, type)

export const removeLoginExperience = () =>
  localStorage.removeItem(LOGIN_EXPERIENCE_KEY)
