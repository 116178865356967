import React from 'react'
import PropTypes from 'prop-types'
import useAuth from 'components/auth/useAuth'
import { intersection, pick } from 'lodash'
import { Button, Modal } from 'lp-components'
import { ContactForm } from '../forms'
import {
  API_KEY_MAP,
  EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES,
  PRIMARY_CONTACT,
  ContactType,
  isMemberAccountType,
  LOGIN_STRATEGY,
} from 'types'
import { MEMBER_PORTAL_URL, PROGRAMS_PORTAL_URL, USER_METADATA } from 'config'
import {
  apiValuesWithMappedKeys,
  formValuesWithMappedKeys,
  formatCommunicationPreferences,
  formatPhoneNumber,
  mergeCommunicationPreferences,
  setObjectIdentifiers,
  useToggle,
} from 'utils'
import activeIcon from 'utility-icons/completed.svg'
import warnIcon from 'utility-icons/warn.svg'

const propTypes = {
  accountType: PropTypes.string.isRequired,
  contact: ContactType,
  country: PropTypes.string.isRequired,
  updateContact: PropTypes.func,
  deleteContact: PropTypes.func,
  fetchContacts: PropTypes.func,
}

function Contact({
  accountType,
  contact,
  country,
  updateContact,
  deleteContact,
  fetchContacts,
}) {
  const { user, logout } = useAuth()
  const loginStrategy = user[USER_METADATA.STRATEGY]

  const [contactExtraDataOpen, toggleContactExtraData] = useToggle()
  const [contactFormOpen, toggleContactForm] = useToggle()
  const [showModal, toggleModal] = useToggle()
  const {
    accountid,
    communication_preferences__c,
    contact_type__c,
    email,
    heroku_external_id__c: contactId,
    firstname,
    lastname,
    mobile_push_notification_opt_in__c: mobilePushOptIn,
    mobilephone,
    phone,
    sfid,
    sms_opt_in__c: smsOptIn,
  } = contact
  const communicationPreferencesString = communication_preferences__c ?? ''
  const isMemberContact = isMemberAccountType(accountType)
  const isPrimaryContact = contact_type__c === PRIMARY_CONTACT
  const isUsernamePasswordLogin =
    loginStrategy === LOGIN_STRATEGY.USERNAME_PASSWORD

  return (
    <div
      className={
        contactExtraDataOpen ? 'accordion-block is-open' : 'accordion-block'
      }
    >
      {showModal && (
        <Modal onClose={() => toggleModal()}>
          <h2>Are you sure you want to remove this contact?</h2>
          {isMemberContact ? (
            <p>
              Removing this contact means they will no longer be covered under
              your membership. They will also no longer received an emails or
              SMS notifications that pertain to your account.
            </p>
          ) : (
            <p>
              Removing this contact means they will no longer receive programs
              portal-related notifications.
            </p>
          )}
          <div className="button-group">
            <Button onClick={toggleModal} className="button-primary-outline">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await deleteContact(contact)
                await fetchContacts(accountid)
              }}
              className="button-warn"
            >
              Remove this Contact
            </Button>
          </div>
        </Modal>
      )}
      <div className="accordion-header">
        <div className="header">
          <div>
            <h3>{[firstname, lastname].filter(Boolean).join(' ') || 'N/A'}</h3>
            <p>{contact_type__c}</p>
          </div>
          <div className="actions">
            {contact_type__c !== PRIMARY_CONTACT && (
              <Button onClick={toggleModal} className="link-secondary">
                Remove Contact
              </Button>
            )}
            <Button onClick={toggleContactForm} className="link-primary">
              Edit Contact
            </Button>
          </div>
        </div>
        <div className="trigger">
          <Button
            className="link-secondary"
            disabled={contactFormOpen}
            onClick={toggleContactExtraData}
          >
            <img
              src="/assets/images/icons/dropdown.svg"
              alt=""
              className="icon"
            />
          </Button>
        </div>
      </div>
      {contactExtraDataOpen && !contactFormOpen && (
        <ContactDetail
          communicationPreferencesString={communicationPreferencesString}
          email={email}
          isMemberContact={isMemberContact}
          mobilePushOptIn={mobilePushOptIn}
          mobilephone={mobilephone}
          phone={phone}
          smsOptIn={smsOptIn}
        />
      )}
      {contactFormOpen && (
        <div className="content">
          <ContactForm
            id={sfid}
            country={country}
            initialValues={formInitialValues(contact)}
            isMemberContact={isMemberContact}
            isPrimaryContact={isPrimaryContact}
            isUsernamePasswordLogin={isUsernamePasswordLogin}
            name={`ContactForm-${sfid}`}
            onSubmit={async (contactFormValues) => {
              const { email: formEmail } = contactFormValues
              const shouldUpdateEmail = isPrimaryContact && formEmail !== email

              const mappedFormValues = pick(
                contactFormValues,
                Object.values(API_KEY_MAP.CONTACT_KEY_MAP),
              )
              const mappedApiValues = apiValuesWithMappedKeys(
                mappedFormValues,
                API_KEY_MAP.CONTACT_KEY_MAP,
              )
              const formCommunicationPreferences =
                formatCommunicationPreferences(contactFormValues)

              const contactApiValues = {
                ...mappedApiValues,
                communication_preferences__c: mergeCommunicationPreferences(
                  formCommunicationPreferences,
                  communicationPreferencesString,
                ),
                auth0_id: user.sub,
                change_auth0_primary: shouldUpdateEmail,
                heroku_external_id: contactId,
              }

              await updateContact(
                setObjectIdentifiers(contactApiValues, contact),
              )

              return { shouldLogout: shouldUpdateEmail }
            }}
            onSubmitSuccess={({ shouldLogout }) => {
              if (shouldLogout) {
                logout({
                  logoutParams: {
                    returnTo: isMemberContact
                      ? MEMBER_PORTAL_URL
                      : PROGRAMS_PORTAL_URL,
                  },
                })
              } else {
                fetchContacts(accountid)
              }
              toggleContactForm()
            }}
            toggleContactForm={toggleContactForm}
          />
        </div>
      )}
    </div>
  )
}

function ContactDetail({
  communicationPreferencesString,
  email,
  isMemberContact,
  mobilePushOptIn,
  mobilephone,
  phone,
  smsOptIn,
}) {
  const allCommunicationPreferences = communicationPreferencesString.split(';')
  const emailPreferences = intersection(
    allCommunicationPreferences,
    Object.values(EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES),
  )
  const emailPreferencesString = emailPreferences.join(', ')

  return (
    <div className="content">
      <div className="row">
        <div className="col-6">
          <p>
            Email:
            {email ? <> {email}</> : <> —</>}
          </p>
          <p>
            Home Phone:
            {phone ? <> {formatPhoneNumber(phone)} </> : <> —</>}
          </p>
          <p>
            Mobile Phone:
            {mobilephone ? <> {formatPhoneNumber(mobilephone)}</> : <> —</>}
          </p>
        </div>
        {isMemberContact && (
          <div className="col-6">
            {emailPreferencesString.length > 0 && (
              <p>
                Email: &#160;
                <img src={activeIcon} alt="" className="status-icon" />
                <em>Enabled for {emailPreferencesString}</em>
              </p>
            )}
            <p>
              SMS: &#160;
              <img
                src={smsOptIn ? activeIcon : warnIcon}
                alt=""
                className="status-icon"
              />
              <em>{smsOptIn ? 'Enabled' : 'Disabled'}</em>
            </p>
            <p>
              Mobile Push Notification: &#160;
              <img
                src={mobilePushOptIn ? activeIcon : warnIcon}
                alt=""
                className="status-icon"
              />
              <em>{mobilePushOptIn ? 'Enabled' : 'Disabled'}</em>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

function formInitialValues(contact) {
  const { communication_preferences__c } = contact
  const communicationPreferencesString = communication_preferences__c ?? ''
  const preference_types = Object.values(
    EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES,
  )
  const initialValues = formValuesWithMappedKeys(
    contact,
    API_KEY_MAP.CONTACT_KEY_MAP,
  )

  preference_types.forEach((preference) => {
    let value = communicationPreferencesString.includes(preference)
    let key = getKeyByValue(
      EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES,
      preference,
    )
    initialValues[key] = value
  })
  return initialValues
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

Contact.propTypes = propTypes

export default Contact
