import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from 'member-portal/views'
import GatedContentLayout from 'member-portal/GatedContentLayout'
import { PATH, makePath } from 'config'

const propTypes = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={makePath(path, PATH.VIDEOS, PATH.VIDEO_ID)}>
        <GatedContentLayout>
          <Views.VideoContent />
        </GatedContentLayout>
      </Route>
    </Switch>
  )
}

Routes.propTypes = propTypes

export default Routes
