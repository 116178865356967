import { configureApi } from 'lp-requests'
import useAuth from 'components/auth/useAuth'

export const useFastApi = () => {
  const { getAccessTokenSilently } = useAuth()

  const fastApiConfig = {
    root: process.env.REACT_APP_SEA_TOW_FAST_API,
    before: async () => {
      const accessToken = await getAccessTokenSilently()
      return {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    },
    onSuccess: (res) => res,
    onFailure: (error) => {
      throw error
    },
    mode: 'cors',
  }

  return configureApi(fastApiConfig)
}