import { jwtDecode } from 'jwt-decode'

const isTokenExpired = (token) => {
  if (!token) return true

  const tokenDetails = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return tokenDetails?.exp < currentTime
}

export default isTokenExpired