import { first } from 'lodash'

// [ Account ]----------------------------------------------------------
export const fetchAccount = {
  shippingstate: 'New York',
  recordtypeid: '01237000000Tgx2AAC',
  shippingpostalcode: '11952-2637',
  billingcity: 'Mattituck',
  shippingcountry: 'United States',
  // shippingcountry: 'Canada',
  name: 'Joseph Macaluso',
  account_detail_type__c: 'Customer - Retail',
  phone: '5858086106',
  isdeleted: false,
  systemmodstamp: '2021-07-09 15:18:15',
  shippingstreet: '2005 Deep Hole Dr',
  billingpostalcode: '11952-2637',
  createddate: '2018-08-13 17:21:14',
  billingstate: 'New York',
  shippingcity: 'Mattituck',
  type: 'General',
  billingcountry: 'United States',
  // billingcountry: 'Canada',
  primary_phone_type__c: null,
  referral_credits__c: 50,
  billingstreet: '2005 Deep Hole Dr',
  sfid: '0013700000ZwKA9AAN',
  id: 10885,
  _hc_lastop: 'SYNCED',
  _hc_err: null,
  heroku_external_id__c: null,
}

export const fetchPartnerAccount = {
  _hc_err: null,
  _hc_lastop: 'SYNCED',
  account_detail_type__c: 'Partner',
  approval_status__c: 'Awaiting Approval',
  approved_services__c: null,
  billingcity: null,
  billingcountry: null,
  billingpostalcode: null,
  billingstate: null,
  billingstreet: null,
  company_logo__c: null,
  createddate: '2020-07-22 12:44:26',
  heroku_external_id__c: null,
  id: 249409,
  isdeleted: false,
  more_than_one_location__c: false,
  name: 'Boat Service',
  parentid: null,
  partner_description__c:
    'Lorem ipsum dolor sit amet, aperiri numquam consulatu his an, errem feugiat vulputate nec ea, fugit dolor usu ad. Tale nostro percipitur te his. Error ocurreret splendide an qui, cum no virtute similique. Nec autem recteque torquatos ut.',
  partner_facebook__c: 'https://www.facebook.com',
  partner_instagram__c: 'https://www.instagram.com',
  partner_linkedin__c: 'https://www.linkedin.com',
  partner_status__c: null,
  phone: '3124573295',
  primary_phone_type__c: null,
  public_facing_email_address__c: 'boat@boatservice.com',
  public_facing_url__c: 'www.boatservice.com',
  recordtypeid: '01237000000Tgx5AAC',
  referral_credits__c: 0,
  services_requested__c: null,
  sfid: '0011G00000ljBXRQA2',
  shippingcity: 'Miamisburg',
  shippingcountry: 'United States',
  shippingpostalcode: '45342',
  shippingstate: 'Ohio',
  shippingstreet: '9443 N Springboro Pike',
  systemmodstamp: '2020-08-03 18:00:30',
  type: 'Partner \\ Program Participant',
}
export const fetchContacts = [
  {
    lastname: 'Macaluso',
    recordtypeid: '01237000000TgqkAAC',
    accountid: '0013700000ZwKA9AAN',
    contact_type__c: 'Primary Contact',
    name: 'Joe Macaluso',
    mobilephone: null,
    phone: '5858086106',
    isdeleted: false,
    homephone: '5858086106',
    systemmodstamp: '2021-07-09 14:49:51',
    contact_status__c: 'Active',
    communication_preferences__c:
      "Sea Tow News;Benefits and Offers;Local Captain's Corner;SMS Opt-In;Do Not Mail;Paperless Renewal",
    createddate: '2018-08-13 17:22:20',
    salutation: null,
    firstname: 'Joe',
    email: 'jmacaluso@seatow.com',
    sfid: '0033700000lMKCsAAO',
    id: 24901,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    heroku_external_id__c: null,
    account__heroku_external_id__c: null,
    incontact_phone_number__c: '5858086106',
    mobile_incontact_number__c: '5858086106',
    account_name__c: 'Joseph Macaluso 452',
  },
  {
    lastname: 'Mac',
    recordtypeid: '01237000000TgqkAAC',
    accountid: '0013700000ZwKA9AAN',
    contact_type__c: 'Boat Co-owner',
    name: 'Sarah Mac',
    mobilephone: '5851119999',
    phone: '5851119999',
    isdeleted: false,
    homephone: '5858086106',
    systemmodstamp: '2021-07-09 14:49:51',
    contact_status__c: 'Active',
    communication_preferences__c:
      "Sea Tow News;Benefits and Offers;Local Captain's Corner",
    createddate: '2018-08-13 17:22:20',
    salutation: null,
    firstname: 'Sarah',
    email: 'smacaluso@seatow.com',
    sfid: '0033700000lMKCsAA1',
    id: 24902,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    heroku_external_id__c: null,
    account__heroku_external_id__c: null,
    incontact_phone_number__c: '5851119999',
    mobile_incontact_number__c: '5851119999',
    account_name__c: 'Joseph Macaluso 452',
  },
  {
    lastname: 'Mac',
    recordtypeid: '01237000000TgqkAAC',
    accountid: '0013700000ZwKA9AAN',
    contact_type__c: 'Spouse Partner',
    name: 'David Mac',
    mobilephone: '5857773333',
    phone: '5857773333',
    isdeleted: false,
    homephone: '(585) 808-6106',
    systemmodstamp: '2021-07-09 14:49:51',
    contact_status__c: 'Active',
    communication_preferences__c:
      "Sea Tow News;Benefits and Offers;Local Captain's Corner",
    createddate: '2018-08-13 17:22:20',
    salutation: null,
    firstname: 'David',
    email: 'dmacaluso@seatow.com',
    sfid: '0033700000lMKCsAA2',
    id: 24903,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    heroku_external_id__c: null,
    account__heroku_external_id__c: null,
    incontact_phone_number__c: '5857773333',
    mobile_incontact_number__c: '5857773333',
    account_name__c: 'Joseph Macaluso 452',
  },
]

export const requestPasswordChangeSuccess = {
  ticket:
    'https://old-surf-0906.us.auth0.com/u/reset-password?ticket=oaKFddSkKBJCaELlXDzbIXYOLktgbHOc#',
}

// [Authentication/Authorization]-------------------------------------------
export const verifyMembershipValid = {
  primary_boat__c: 'a033700000A5nfiAAB',
  card_security_code__c: '****',
  auto_renew__c: true,
  trailer_care_type__c: 'Trailer Care Marine',
  referral_credit_amount__c: 0,
  membership_status__c: 'Active',
  card_name__c: 'Joe Macaluso',
  card_number__c: '************1111',
  name: 'MKEY-0002188173',
  membership_contact__c: '0033700000lMKCsAAO',
  member_since__c: null,
  membership_expiration_date__c: '2022-09-30',
  heroku_external_id__c: null,
  isdeleted: false,
  systemmodstamp: '2021-04-02 14:23:13',
  membership_number__c: '4625162',
  renewal_promotion_code__c: null,
  card_expiration_date__c: '01/2030',
  createddate: '2018-08-13 17:23:34',
  account__c: '0013700000ZwKA9AAN',
  card_type__c: 'Visa',
  renewal_promo_dollar_value__c: 0,
  sfid: 'a003700000AlpQPAAZ',
  id: 6747,
  _hc_lastop: 'SYNCED',
  _hc_err: null,
  account__r__heroku_external_id__c: null,
  membership_contact__r__heroku_external_id__c: null,
  last_4_digits__c: '7919',
  membership_type__c: 'Gold Card',
  primary_membership__c: true,
}

export const privateVideoDetails = {
  id: 20371,
  categories: ["Category Name"],
  title: "Lorem ipsum dolor sit amet consectetur",
  short_description: "Lorem ipsum dolor sit amet",
  long_description: "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam vero officiis ducimus cumque corporis in nesciunt blanditiis ea aspernatur quas, voluptas, voluptatem amet minus ipsa magnam, vel sequi saepe numquam.</p>",
  private: true,
  video_uid: "58394bba5994cbff442eeb98e889c12c",
  imgLink: "https://seatow.com/wp-content/uploads/2022/10/maine-priority-service-sea-tow.webp"
}

export const publicVideoDetails = {
  id: 20309,
  categories: ["Category Name", "Second Name"],
  title: "Lorem ipsum dolor sit amet consectetur",
  short_description: "Lorem ipsum dolor sit amet",
  long_description: "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam vero officiis ducimus cumque corporis in nesciunt blanditiis ea aspernatur quas, voluptas, voluptatem amet minus ipsa magnam, vel sequi saepe numquam.</p>",
  private: false,
  video_uid: "a5b42c9322b9632f0b91d84c6d881f39",
  imgLink: "https://seatow.com/wp-content/uploads/2022/10/give-the-gift-of-sea-tow-membership-to-dad.webp",
  keywords: "Lorem ipsum odor amet"
}

// [ Google Places Response]-------------------------------------------
export const homePortLocation = [
  {
    business_status: 'OPERATIONAL',
    formatted_address: '62300 NY-25, Southold, NY 11971, United States',
    geometry: {
      location: {
        lat: 41.0767039,
        lng: -72.4034593,
      },
      viewport: {
        northeast: {
          lat: 41.07805372989272,
          lng: -7240210947010728,
        },
        southwest: {
          lat: 41.07535407010728,
          lng: -72.40480912989271,
        },
      },
    },
    icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/shopping-71.png',
    icon_background_color: '#4B96F3',
    icon_mask_base_uri:
      'https://maps.gstatic.com/mapfiles/place_api/icons/v2/shopping_pinlet',
    name: 'Port of Egypt Marine Inc',
    opening_hours: {
      open_now: false,
    },
    photos: [
      {
        height: 361,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/104062384289590367902">Port of Egypt Marine Inc</a>',
        ],
        photo_reference:
          'Aap_uECGvK0MtT7CaLyFQWPgGdIm5m07MgPBejmFFQfBeobjgzm9YGOaw2jQtsmfAJkxqOrr5PAB5eOYq9ivMjeTK0H0VTsHyaA2zm7BaEN8n5Q7ZDZMV9QrHeKtvq2858VxpXM58YjfzuVe6fSthIbC47R1KS5KO2K-bRhTJV1RrGVTBkQj',
        width: 756,
      },
    ],
    place_id: 'ChIJuXmXfQ2Z6IkRwQpHtGK39Tg',
    plus_code: {
      compound_code: '3HGW+MJ Southold, New York',
      global_code: '87H93HGW+MJ',
    },
    rating: 4.1,
    reference: 'ChIJuXmXfQ2Z6IkRwQpHtGK39Tg',
    types: [
      'gas_station',
      'storage',
      'restaurant',
      'food',
      'school',
      'point_of_interest',
      'store',
      'establishment',
    ],
    user_ratings_total: 13,
  },
  {
    formatted_address: 'Alexandria, VA, USA',
    geometry: {
      location: {
        lat: 38.8848355,
        lng: -77.0469214,
      },
      viewport: {
        northeast: {
          lat: 38.845011,
          lng: -77.0372879,
        },
        southwest: {
          lat: 38.845011,
          lng: -77.144359,
        },
      },
    },
    icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png',
    icon_background_color: '#7B9EB0',
    icon_mask_base_uri:
      'https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet',
    name: 'Alexandria',
    photos: [
      {
        height: 3024,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/104260620589904820636">Brian Zuniga</a>',
        ],
        photo_reference:
          'Aap_uEAzqKoVQD7UH1k97GMj0pK7P5-tZ2ukT032g3AIfc0DP2LnlFD7f1D8UwqVChrfnLu4DNdORC2jwsCPrpEQ2VSYC88pimzXYJycq1HSuL1yEgzQ_o8vhhK0xAYl6eRJHOS_xXe0nl9nTu6TmBunSn7H6EJyLfbif-3Awrm3A7WOSRM-bRhTJV1RrGVTBkQj',
        width: 4032,
      },
    ],
    place_id: 'ChIJ8aukkz5NtokRLAHB24Ym9dc',
    reference: 'ChIJ8aukkz5NtokRLAHB24Ym9dc',
    types: ['locality', 'political'],
  },
]

// [ HomeportWizard Sample Response ]------------------------------------------------------
export const testPortWizardData = [
  {
    homePortType: 'Marina',
    homePortName: 'Port of Egypt',
    homePortLocation: {
      business_status: 'OPERATIONAL',
      formatted_address: '62300 NY-25, Southold, NY 11971, United States',
      geometry: {
        location: {
          lat: 41.0767039,
          lng: -72.4034593,
        },
        viewport: {
          northeast: {
            lat: 41.07805372989272,
            lng: -7240210947010728,
          },
          southwest: {
            lat: 41.07535407010728,
            lng: -72.40480912989271,
          },
        },
      },
      icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/shopping-71.png',
      icon_background_color: '#4B96F3',
      icon_mask_base_uri:
        'https://maps.gstatic.com/mapfiles/place_api/icons/v2/shopping_pinlet',
      name: 'Port of Egypt Marine Inc',
      opening_hours: {
        open_now: false,
      },
      photos: [
        {
          height: 361,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/104062384289590367902">Port of Egypt Marine Inc</a>',
          ],
          photo_reference:
            'Aap_uECGvK0MtT7CaLyFQWPgGdIm5m07MgPBejmFFQfBeobjgzm9YGOaw2jQtsmfAJkxqOrr5PAB5eOYq9ivMjeTK0H0VTsHyaA2zm7BaEN8n5Q7ZDZMV9QrHeKtvq2858VxpXM58YjfzuVe6fSthIbC47R1KS5KO2K-bRhTJV1RrGVTBkQj',
          width: 756,
        },
      ],
      place_id: 'ChIJuXmXfQ2Z6IkRwQpHtGK39Tg',
      plus_code: {
        compound_code: '3HGW+MJ Southold, New York',
        global_code: '87H93HGW+MJ',
      },
      rating: 4.1,
      reference: 'ChIJuXmXfQ2Z6IkRwQpHtGK39Tg',
      types: [
        'gas_station',
        'storage',
        'restaurant',
        'food',
        'school',
        'point_of_interest',
        'store',
        'establishment',
      ],
      user_ratings_total: 13,
    },
  },
  {
    homePortType: 'Mooring',
    homePortLocation: {
      formatted_address: 'Alexandria, VA, USA',
      geometry: {
        location: {
          lat: 38.8848355,
          lng: -77.0469214,
        },
        viewport: {
          northeast: {
            lat: 38.845011,
            lng: -77.0372879,
          },
          southwest: {
            lat: 38.845011,
            lng: -77.144359,
          },
        },
      },
      icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png',
      icon_background_color: '#7B9EB0',
      icon_mask_base_uri:
        'https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet',
      name: 'Alexandria',
      photos: [
        {
          height: 3024,
          html_attributions: [
            '<a href="https://maps.google.com/maps/contrib/104260620589904820636">Brian Zuniga</a>',
          ],
          photo_reference:
            'Aap_uEAzqKoVQD7UH1k97GMj0pK7P5-tZ2ukT032g3AIfc0DP2LnlFD7f1D8UwqVChrfnLu4DNdORC2jwsCPrpEQ2VSYC88pimzXYJycq1HSuL1yEgzQ_o8vhhK0xAYl6eRJHOS_xXe0nl9nTu6TmBunSn7H6EJyLfbif-3Awrm3A7WOSRM-bRhTJV1RrGVTBkQj',
          width: 4032,
        },
      ],
      place_id: 'ChIJ8aukkz5NtokRLAHB24Ym9dc',
      reference: 'ChIJ8aukkz5NtokRLAHB24Ym9dc',
      types: ['locality', 'political'],
    },
  },
]

// [ Membership Card ]------------------------------------------------------
export const fetchMembershipFulfillments = [
  {
    boat__c: 'a033700000A5nfiAAB',
    account__r__heroku_external_id__c: null,
    name: 'FLFID-0000569493',
    heroku_external_id__c: null,
    isdeleted: false,
    systemmodstamp: '2021-07-09 14:50:05',
    fulfillment_code__c: 'N',
    invoice__c: '0067b00000HP1ePAAT',
    createddate: '2021-07-09 14:50:05',
    account__c: '0013700000ZwKA9AAN',
    boat__r__heroku_external_id__c: null,
    sfid: 'a1Q7b000000ki05EAA',
    id: 556648,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    order_date__c: '2021-07-09',
    processed_date__c: null,
    shipped_date__c: null,
    status: 'New Member Card Package',
    title: 'Annual Membership Card',
    most_recent_date: '2021-07-09',
  },
  {
    boat__c: 'a033700000A5nfiAAB',
    account__r__heroku_external_id__c: null,
    name: 'FLFID-0000569494',
    heroku_external_id__c: null,
    isdeleted: false,
    systemmodstamp: '2021-07-09 15:14:56',
    fulfillment_code__c: 'R',
    invoice__c: '0067b00000HP1i7AAD',
    createddate: '2021-07-09 15:14:56',
    account__c: '0013700000ZwKA9AAN',
    boat__r__heroku_external_id__c: null,
    sfid: 'a1Q7b000000ki0AEAQ',
    id: 556649,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    order_date__c: '2021-07-09',
    processed_date__c: null,
    shipped_date__c: null,
    status: 'Renewing Membership Card Package',
    title: 'Annual Membership Card',
    most_recent_date: '2021-07-09',
  },
  {
    boat__c: 'a033700000A5nfiAAB',
    account__r__heroku_external_id__c: null,
    name: 'FLFID-0000569495',
    heroku_external_id__c: null,
    isdeleted: false,
    systemmodstamp: '2021-07-09 15:18:09',
    fulfillment_code__c: 'R',
    invoice__c: '0067b00000HP1iWAAT',
    createddate: '2021-07-09 15:18:09',
    account__c: '0013700000ZwKA9AAN',
    boat__r__heroku_external_id__c: null,
    sfid: 'a1Q7b000000ki0FEAQ',
    id: 556650,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    order_date__c: '2021-07-09',
    processed_date__c: null,
    shipped_date__c: null,
    status: 'Renewing Membership Card Package',
    title: 'Annual Membership Card',
    most_recent_date: '2021-07-09',
  },
  {
    boat__c: 'a033700000A5nfiAAB',
    account__r__heroku_external_id__c: null,
    name: 'FLFID-0000569497',
    heroku_external_id__c: null,
    isdeleted: false,
    systemmodstamp: '2021-07-09 19:29:59',
    fulfillment_code__c: 'R',
    invoice__c: '0067b00000HP2bcAAD',
    createddate: '2021-07-09 19:29:59',
    account__c: '0013700000ZwKA9AAN',
    boat__r__heroku_external_id__c: null,
    sfid: 'a1Q7b000000ki21EAA',
    id: 556652,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    order_date__c: '2021-07-09',
    processed_date__c: null,
    shipped_date__c: null,
    status: 'Renewing Membership Card Package',
    title: 'Annual Membership Card',
    most_recent_date: '2021-07-09',
  },
  {
    boat__c: 'a033700000A5nfiAAB',
    account__r__heroku_external_id__c: null,
    name: 'FLFID-0000569498',
    heroku_external_id__c: null,
    isdeleted: false,
    systemmodstamp: '2021-07-12 14:16:28',
    fulfillment_code__c: 'R',
    invoice__c: '0067b00000HP6NNAA1',
    createddate: '2021-07-12 14:16:28',
    account__c: '0013700000ZwKA9AAN',
    boat__r__heroku_external_id__c: null,
    sfid: 'a1Q7b000000kiMpEAI',
    id: 556653,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    order_date__c: '2021-07-12',
    processed_date__c: null,
    shipped_date__c: null,
    status: 'Renewing Membership Card Package',
    title: 'Annual Membership Card',
    most_recent_date: '2021-07-12',
  },
]

// [ Memberships ]----------------------------------------------------------
const membershipAccount = {
  shippingstate: 'New York',
  recordtypeid: '01237000000Tgx2AAC',
  shippingpostalcode: '11952-2637',
  billingcity: 'Mattituck',
  shippingcountry: 'United States',
  name: 'Joseph Macaluso',
  account_detail_type__c: 'Customer - Retail',
  phone: '5858086106',
  isdeleted: false,
  systemmodstamp: '2021-07-12 14:16:29',
  shippingstreet: '2005 Deep Hole Dr',
  billingpostalcode: '11952-2637',
  createddate: '2018-08-13 17:21:14',
  billingstate: 'New York',
  shippingcity: 'Mattituck',
  type: 'General',
  billingcountry: 'United States',
  primary_phone_type__c: null,
  billingstreet: '2005 Deep Hole Dr',
  referral_credits__c: 50,
  sfid: '0013700000ZwKA9AAN',
  id: 10885,
  _hc_lastop: 'SYNCED',
  _hc_err: null,
  heroku_external_id__c: 'b389af97-35d6-4a04-a364-79df83ecd520',
}

const membershipAor = {
  active_owner__c: true,
  alternate_24_hour_phone__c: null,
  aor_contact_phone__c: null,
  aor_description__c: 'Mock AOR Detail',
  aor_email__c: 'memployee@seatow.com',
  aor_home_page_url__c: 'https://www.seatow.com',
  aor_region_name__c: 'Region #12 Out-of All Areas',
  aor_sales_tax_rate__c: 1,
  createddate: '2020-07-14 19:40:45',
  employee_photo_url__c:
    'https://images.unsplash.com/photo-1485528562718-2ae1c8419ae2?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  facebook__c: null,
  id: 19,
  instagram__c: 'https://www.instagram.com/petesouza/',
  isdeleted: false,
  linkedin__c: 'https://www.linkedin.com/in/michaelwislek/',
  location__latitude__s: null,
  location__longitude__s: null,
  name: 'Captain Josiah Ahab',
  owner_contact__c: '0031G000011OHXOQA4',
  public_mailing_city__c: null,
  public_mailing_country__c: 'United States',
  public_mailing_postal_code__c: null,
  public_mailing_state__c: null,
  public_mailing_street__c: null,
  serving_statement__c: null,
  sfid: 'a0a1G000006doqiQAA',
  status__c: 'Active',
  systemmodstamp: '2020-07-20 13:29:08',
  x24_hour_phone__c: '(888) 555-0000',
  youtube__c: 'https://youtu.be/dShpaU9HdqM',
  _hc_err: null,
  _hc_lastop: null,
}

const membershipBoats = [
  // {
  //   fuel_type__c: 'Gasoline',
  //   color__c: 'Black',
  //   zip__c: null,
  //   geolocation2__latitude__s: 40.715247485109515,
  //   geolocation2__longitude__s: -74.03876012299804,
  //   registration_document__c: 'New York-1337',
  //   contact__c: '0033700000lMKCsAAO',
  //   number_of_engines__c: '2',
  //   primary_boat__c: false,
  //   year__c: '2018',
  //   home_port_country__c: 'United States',
  //   home_port_type__c: 'Marina',
  //   name: 'BTID-0002091274',
  //   engine_type__c: 'Outboard',
  //   address__c: null,
  //   heroku_external_id__c: null,
  //   isdeleted: false,
  //   home_port_state__c: 'Connecticut',
  //   systemmodstamp: '2019-09-18 20:42:45',
  //   homeport_state__c: null,
  //   state__c: null,
  //   boat_status__c: 'Active',
  //   hin__c: null,
  //   createddate: '2019-08-19 19:57:52',
  //   account__c: '0013700000ZwKA9AAN',
  //   boat_make__c: 'Sea Titan',
  //   hull_type__c: 'Aluminum',
  //   marina_name__c: null,
  //   name__c: 'Ramen Town 4.0',
  //   city__c: null,
  //   related_membership__c: 'a003700000AlpQPAAZ',
  //   home_port_city__c: 'New London',
  //   length__c: 38,
  //   aor__c: 'a0d37000004fplvAAA',
  //   sfid: 'a031G00000BSZcTQAX',
  //   id: 226882,
  //   _hc_lastop: null,
  //   _hc_err: null,
  //   related_membership__r__heroku_external_id__c: null,
  //   account__r__heroku_external_id__c: null,
  //   contact__r__heroku_external_id__c: null,
  //   boat_transfer_type__c: null,
  // },
  {
    no_boat_reason__c: 'Boat Club Member',
    name_of_boat_club__c: 'New York Harbor Club',
    fuel_type__c: 'null',
    color__c: 'null',
    zip__c: null,
    geolocation2__latitude__s: 40.715247485109515,
    geolocation2__longitude__s: -74.03876012299804,
    registration_document__c: null,
    contact__c: '0033700000lMKCsAAO',
    number_of_engines__c: null,
    primary_boat__c: false,
    year__c: null,
    home_port_country__c: 'United States',
    home_port_type__c: 'Mooring',
    name: 'BTID-0002045799',
    engine_type__c: null,
    address__c: null,
    heroku_external_id__c: null,
    isdeleted: false,
    home_port_state__c: 'New York',
    systemmodstamp: '2021-04-02 14:23:12',
    homeport_state__c: null,
    state__c: null,
    boat_status__c: 'Active',
    hin__c: null,
    createddate: '2018-08-13 17:24:48',
    account__c: '0013700000ZwKA9AAN',
    boat_make__c: null,
    hull_type__c: null,
    marina_name__c: 'Port of Egypt',
    name__c: null,
    city__c: null,
    related_membership__c: 'a007h000003m5g9AAA',
    home_port_city__c: 'New York',
    length__c: null,
    aor__c: 'a0d1G000006dG6RQAU',
    sfid: 'a033700000A5nfiAZZ',
    id: 36693,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    related_membership__r__heroku_external_id__c: null,
    account__r__heroku_external_id__c: null,
    contact__r__heroku_external_id__c: null,
    boat_transfer_type__c: 'Assigned to wrong AOR - mistake - Full',
  },
  {
    fuel_type__c: 'Gasoline',
    color__c: 'Black',
    zip__c: null,
    geolocation2__latitude__s: 40.715247485109515,
    geolocation2__longitude__s: -74.03876012299804,
    registration_document__c: 'New York-1337',
    contact__c: '0033700000lMKCsAAO',
    number_of_engines__c: '2',
    primary_boat__c: true,
    year__c: '2018',
    home_port_country__c: 'United States',
    home_port_type__c: 'Marina',
    name: 'BTID-0002045799',
    engine_type__c: 'Outboard',
    address__c: null,
    heroku_external_id__c: null,
    isdeleted: false,
    home_port_state__c: 'New York',
    systemmodstamp: '2021-04-02 14:23:12',
    homeport_state__c: null,
    state__c: null,
    boat_status__c: 'Active',
    hin__c: null,
    createddate: '2018-08-13 17:24:48',
    account__c: '0013700000ZwKA9AAN',
    boat_make__c: 'Sea Ray',
    hull_type__c: 'Aluminum',
    marina_name__c: 'Port of Egypt',
    name__c: 'Ramen Town 3.0',
    city__c: null,
    related_membership__c: 'a003700000AlpQPAAZ',
    home_port_city__c: 'Southold',
    length__c: 39,
    aor__c: 'a0d1G000006dG6RQAU',
    sfid: 'a033700000A5nfiAAB',
    id: 36692,
    _hc_lastop: 'SYNCED',
    _hc_err: null,
    related_membership__r__heroku_external_id__c: null,
    account__r__heroku_external_id__c: null,
    contact__r__heroku_external_id__c: null,
    boat_transfer_type__c: 'Assigned to wrong AOR - mistake - Full',
  },
  // {
  //   fuel_type__c: 'Gasoline',
  //   color__c: 'White',
  //   zip__c: null,
  //   geolocation2__latitude__s: 40.715247485109515,
  //   geolocation2__longitude__s: -74.03876012299804,
  //   registration_document__c: 'New York-1337',
  //   contact__c: '0033700000lMKCsAAO',
  //   number_of_engines__c: '2',
  //   primary_boat__c: false,
  //   year__c: '2018',
  //   home_port_country__c: 'United States',
  //   home_port_type__c: 'Marina',
  //   name: 'BTID-0002045799',
  //   engine_type__c: 'Outboard',
  //   address__c: null,
  //   heroku_external_id__c: null,
  //   isdeleted: false,
  //   home_port_state__c: 'New York',
  //   systemmodstamp: '2021-04-02 14:23:12',
  //   homeport_state__c: null,
  //   state__c: null,
  //   boat_status__c: 'Active',
  //   hin__c: null,
  //   createddate: '2018-08-13 17:24:48',
  //   account__c: '0013700000ZwKA9AAN',
  //   boat_make__c: 'Sea Dragon',
  //   hull_type__c: 'Aluminum',
  //   marina_name__c: 'Port of Egypt',
  //   name__c: 'Ramen Town 3.0',
  //   city__c: null,
  //   related_membership__c: 'a003700000AlpQPAAZ',
  //   home_port_city__c: 'Southold',
  //   length__c: 39,
  //   aor__c: 'a0d1G000006dG6RQAU',
  //   sfid: 'a033700000A5nfiAZZ',
  //   id: 36693,
  //   _hc_lastop: 'SYNCED',
  //   _hc_err: null,
  //   related_membership__r__heroku_external_id__c: null,
  //   account__r__heroku_external_id__c: null,
  //   contact__r__heroku_external_id__c: null,
  //   boat_transfer_type__c: 'Assigned to wrong AOR - mistake - Full',
  // },
  // {
  //   fuel_type__c: 'Gasoline',
  //   color__c: 'Black',
  //   zip__c: null,
  //   geolocation2__latitude__s: 40.715247485109515,
  //   geolocation2__longitude__s: -74.03876012299804,
  //   registration_document__c: 'New York-1337',
  //   contact__c: '0033700000lMKCsAAO',
  //   number_of_engines__c: '2',
  //   primary_boat__c: false,
  //   year__c: '2018',
  //   home_port_country__c: 'United States',
  //   home_port_type__c: 'Marina',
  //   name: 'BTID-0002045799',
  //   engine_type__c: 'Outboard',
  //   address__c: null,
  //   heroku_external_id__c: null,
  //   isdeleted: false,
  //   home_port_state__c: 'New York',
  //   systemmodstamp: '2021-04-02 14:23:12',
  //   homeport_state__c: null,
  //   state__c: null,
  //   boat_status__c: 'Active',
  //   hin__c: null,
  //   createddate: '2018-08-13 17:24:48',
  //   account__c: '0013700000ZwKA9AAN',
  //   boat_make__c: 'Sea Anemone',
  //   hull_type__c: 'Aluminum',
  //   marina_name__c: 'Port of Egypt',
  //   name__c: 'Ramen Town 3.0',
  //   city__c: null,
  //   related_membership__c: 'a003700000AlpQPAAZ',
  //   home_port_city__c: 'Southold',
  //   length__c: 39,
  //   aor__c: 'a0d1G000006dG6RQAU',
  //   sfid: 'a033700000A5nfiAWY',
  //   id: 36694,
  //   _hc_lastop: 'SYNCED',
  //   _hc_err: null,
  //   related_membership__r__heroku_external_id__c: null,
  //   account__r__heroku_external_id__c: null,
  //   contact__r__heroku_external_id__c: null,
  //   boat_transfer_type__c: 'Assigned to wrong AOR - mistake - Full',
  // },
]

const membershipPaymentProfile = {
  account__c: '0013700000ZwKA9AAN',
  account__r__heroku_external_id__c: null,
  auto_renew__c: true,
  card_expiration_date__c: '01/2030',
  card_name__c: 'Joe Macaluso',
  card_number__c: '4111111111111111',
  card_security_code__c: '421',
  card_type__c: 'Visa',
  createddate: '2018-08-13 17:23:34',
  heroku_external_id__c: null,
  id: 6747,
  isdeleted: false,
  last_4_digits__c: '1111',
  member_since__c: null,
  membership_contact__c: '0033700000lMKCsAAO',
  membership_contact__r__heroku_external_id__c: null,
  membership_expiration_date__c: '2026-07-10',
  membership_number__c: '4625162',
  membership_status__c: 'Active',
  membership_type__c: 'Commercial Card',
  name: 'MKEY-0002188173',
  primary_boat__c: 'a033700000A5nfiAAB',
  primary_membership__c: true,
  referral_credit_amount__c: 0,
  renewal_promo_dollar_value__c: 0,
  renewal_promotion_code__c: null,
  sfid: 'a003700000AlpQPAAZ',
  systemmodstamp: '2021-07-21 21:10:18',
  trailer_care_type__c: 'Trailer Care Marine',
  _hc_err: null,
  _hc_lastop: 'SYNCED',
}

const baseMembership = {
  account_name: 'Joe Macaluso',
  primary_boat__c: 'a033700000A5nfiAAB',
  card_security_code__c: '****',
  auto_renew__c: true,

  // trailer_care_type__c: 'Trailer Care Marine',
  // trailer_care_type__c: 'Trailer Care Universal',
  trailer_care_type__c: null,

  referral_credit_amount__c: 0,
  membership_status__c: 'Active',
  card_name__c: 'Joe Macaluso',
  card_number__c: '************1111',
  communication_member_renewal_amount__c: 199,
  name: 'MKEY-0002188173',
  membership_contact__c: '0033700000lMKCsAAO',
  member_since__c: null,
  membership_expiration_date__c: '2024-09-30',
  membership_effective_date__c: '2021-04-10 17:11:41',
  multiyear_product: false,
  heroku_external_id__c: '4ecd1c34-bb3b-4ca0-bda5-0d48be27a88f',
  isdeleted: false,
  systemmodstamp: '2021-04-02 14:23:13',
  membership_number__c: '4625162',
  renewal_promotion_code__c: null,
  card_expiration_date__c: '01/2030',
  createddate: '2018-08-13 17:23:34',
  account__c: '0013700000ZwKA9AAN',
  card_type__c: 'Visa',
  renewal_promo_dollar_value__c: 0,
  sfid: 'a003700000AlpQPAAZ',
  id: 6747,
  _hc_lastop: 'SYNCED',
  _hc_err: null,
  account__r__heroku_external_id__c: 'b389af97-35d6-4a04-a364-79df83ecd520',
  membership_contact__r__heroku_external_id__c: null,
  last_4_digits__c: '1111',

  // membership_type__c: 'Commercial Card',
  // membership_type__c: 'Fleet Card',
  membership_type__c: 'Gold Card',
  // membership_type__c: 'Lake Card',
  // membership_type__c: 'Lake Fleet Card',
  // membership_type__c: 'Professional Mariner Card',
  // membership_type__c: 'Trial Membership',

  primary_membership__c: true,
  boats: membershipBoats,
  auto_renew_date: '2026-06-30',
  aor: membershipAor,
  payment_profiles: membershipPaymentProfile,
}

export const newMembershipNoBoats = {
  account: {
    account_detail_type__c: 'Customer - Retail',
    approval_status__c: null,
    approved_services__c: null,
    billingcity: null,
    billingcountry: null,
    billingpostalcode: null,
    billingstate: null,
    billingstreet: null,
    company_logo__c: null,
    company_logo_document_id__c: null,
    createddate: '2021-10-19 17:14:14',
    heroku_external_id__c: null,
    id: 341245,
    isdeleted: false,
    more_than_one_location__c: false,
    name: 'Memberapply Test2',
    parentid: null,
    partner_description__c: null,
    partner_facebook__c: null,
    partner_instagram__c: null,
    partner_linkedin__c: null,
    partner_status__c: null,
    phone: null,
    primary_phone_type__c: null,
    public_facing_email_address__c: null,
    public_facing_url__c: null,
    recordtypeid: '01237000000Tgx2AAC',
    referral_credits__c: 0.0,
    services_requested__c: null,
    sfid: '0017h00000dXxAxAAK',
    shippingcity: null,
    shippingcountry: null,
    shippinglatitude: null,
    shippinglongitude: null,
    shippingpostalcode: null,
    shippingstate: null,
    shippingstreet: null,
    systemmodstamp: '2021-10-26 18:16:09',
    type: 'General',
    _hc_err: null,
    _hc_lastop: 'SYNCED',
  },
  memberships: [
    {
      account__c: '0017h00000dXxAxAAK',
      account__r__heroku_external_id__c: null,
      arb_last_4__c: null,
      arb_temp_from_web__c: false,
      auto_renew__c: false,
      card_expiration_date__c: null,
      card_name__c: null,
      card_number__c: null,
      card_security_code__c: null,
      card_type__c: null,
      createddate: '2021-10-26 18:16:07',
      heroku_external_id__c: null,
      id: 342692,
      isdeleted: false,
      last_4_digits__c: null,
      member_since__c: null,
      membership_contact__c: '0037h00000WMw1hAAD',
      membership_contact__r__heroku_external_id__c: null,
      membership_effective_date__c: null,
      membership_expiration_date__c: null,
      membership_number__c: '4823045',
      membership_status__c: null,
      membership_type__c: null,
      name: 'MKEY-0002386056',
      primary_boat__c: null,
      primary_membership__c: true,
      referral_credit_amount__c: 0.0,
      renewal_promo_dollar_value__c: 0.0,
      renewal_promotion_code__c: null,
      sfid: 'a007h0000042XmXAAU',
      systemmodstamp: '2021-10-26 18:16:07',
      trailer_care__c: null,
      trailer_care_type__c: null,
      _hc_err: null,
      _hc_lastop: 'SYNCED',
      boats: [
        /* Boat that is currently in Active Membership - Needs to be Removed */
        {
          fuel_type__c: null,
          color__: null,
          zip__c: null,
          registration_document__c: null,
          contact__c: '0037h00000WMw1hAAD',
          number_of_engines__c: null,
          primary_boat__c: true,
          year__c: '2020',
          related_membership__r__heroku_external_id__c: null,
          account__r__heroku_external_id__c: null,
          home_port_country__c: 'United States',
          home_port_type__c: 'Mooring',
          name: 'BTID-0002189657',
          engine_type__c: null,
          address__c: '62300 New York 25',
          heroku_external_id__c: null,
          isdeleted: false,
          home_port_state__c: 'New York',
          systemmodstamp: '2021-10-26 23:09:03',
          homeport_state__c: null,
          state__c: null,
          boat_status__c: 'Active',
          boat_transfer_type__c: 'Change HP AOR mid-mbrshp/on renew - Partial',
          hin__c: null,
          createddate: '2021-10-26 23:09:03',
          account__c: '0017h00000dXxAxAAK',
          boat_make__c: 'Sea Dog',
          hull_type__c: null,
          marina_name__c: 'Port of Egypt Marine Inc',
          name__c: null,
          city__c: null,
          related_membership__c: 'a007h0000042XmXAAU',
          home_port_city__c: 'Southold',
          contact__r__heroku_external_id__c: null,
          length__c: 35.0,
          aor__c: 'a0d37000004fpkWAAQ',
          sfid: 'a037h000000GPMiAAO',
          id: 383571,
          _hc_lastop: 'SYNCED',
          _hc_err: null,
          name_of_boat_club__c: null,
          no_boat_reason__c: null,
          geolocation2__longitude__s: -72.4034593,
          geolocation2__latitude__s: 41.0767039,
        },
        /* Test with Boat Type */
        // {
        //   fuel_type__c: 'Gasoline',
        //   color__c: 'White',
        //   zip__c: null,
        //   geolocation2__latitude__s: 40.715247485109515,
        //   geolocation2__longitude__s: -74.03876012299804,
        //   registration_document__c: 'New York-1337',
        //   contact__c: '0033700000lMKCsAAO',
        //   number_of_engines__c: '2',
        //   primary_boat__c: true,
        //   year__c: '2018',
        //   home_port_country__c: 'United States',
        //   home_port_type__c: 'Marina',
        //   name: 'BTID-0002045799',
        //   engine_type__c: 'Outboard',
        //   address__c: null,
        //   heroku_external_id__c: null,
        //   isdeleted: false,
        //   home_port_state__c: 'New York',
        //   systemmodstamp: '2021-04-02 14:23:12',
        //   homeport_state__c: null,
        //   state__c: null,
        //   boat_status__c: 'Active',
        //   hin__c: null,
        //   createddate: '2018-08-13 17:24:48',
        //   account__c: '0013700000ZwKA9AAN',
        //   boat_make__c: 'Sea Dragon',
        //   hull_type__c: 'Aluminum',
        //   marina_name__c: 'Port of Egypt',
        //   name__c: 'Ramen Town 3.0',
        //   city__c: null,
        //   related_membership__c: 'a003700000AlpQPAAZ',
        //   home_port_city__c: 'Southold',
        //   length__c: 39,
        //   aor__c: 'a0d1G000006dG6RQAU',
        //   sfid: 'a007h000003m5g9AAA',
        //   id: 36693,
        //   _hc_lastop: 'SYNCED',
        //   _hc_err: null,
        //   related_membership__r__heroku_external_id__c: null,
        //   account__r__heroku_external_id__c: null,
        //   contact__r__heroku_external_id__c: null,
        //   boat_transfer_type__c: 'Assigned to wrong AOR - mistake - Full',
        // },
        /* Test with Other Boat Type */
        {
          no_boat_reason__c: 'Boat Club Member',
          name_of_boat_club__c: 'New York Harbor Club',
          fuel_type__c: 'null',
          color__c: 'null',
          zip__c: null,
          geolocation2__latitude__s: 40.715247485109515,
          geolocation2__longitude__s: -74.03876012299804,
          registration_document__c: null,
          contact__c: '0033700000lMKCsAAO',
          number_of_engines__c: null,
          primary_boat__c: true,
          year__c: null,
          home_port_country__c: 'United States',
          home_port_type__c: 'Mooring',
          name: 'BTID-0002045799',
          engine_type__c: null,
          address__c: null,
          heroku_external_id__c: null,
          isdeleted: false,
          home_port_state__c: 'New York',
          systemmodstamp: '2021-04-02 14:23:12',
          homeport_state__c: null,
          state__c: null,
          boat_status__c: 'Active',
          hin__c: null,
          createddate: '2018-08-13 17:24:48',
          account__c: '0013700000ZwKA9AAN',
          boat_make__c: null,
          hull_type__c: null,
          marina_name__c: 'Port of Egypt',
          name__c: null,
          city__c: null,
          related_membership__c: 'a007h000003m5g9AAA',
          home_port_city__c: 'New York',
          length__c: null,
          aor__c: 'a0d1G000006dG6RQAU',
          sfid: 'a033700000A5nfiAZZ',
          id: 36693,
          _hc_lastop: 'SYNCED',
          _hc_err: null,
          related_membership__r__heroku_external_id__c: null,
          account__r__heroku_external_id__c: null,
          contact__r__heroku_external_id__c: null,
          boat_transfer_type__c: 'Assigned to wrong AOR - mistake - Full',
        },
      ],
      aor: {
        youtube__c: null,
        aor_sales_tax_rate__c: 1.0,
        aor_description__c: 'Eastern Long Island',
        aor_email__c: 'easternli@seatow.com',
        linkedin__c: null,
        serving_statement__c: null,
        aor_region_name__c: 'Region #2 Northern (CT,Long Island)',
        active_owner__c: true,
        x24_hour_phone__c: '(631) 765-5300',
        name: '600',
        public_mailing_postal_code__c: '11971',
        public_mailing_city__c: 'Southold',
        owner_contact__c: '0031G00000xWFUoQAO',
        aor_contact_phone__c: '(631) 765-5300',
        isdeleted: false,
        systemmodstamp: '2021-04-27 15:49:25',
        status__c: 'Active',
        instagram__c: null,
        location__latitude__s: 41.039215,
        public_mailing_street__c: '2700 Hobart Road',
        createddate: '2017-06-20 16:39:01',
        public_mailing_country__c: 'United States',
        alternate_24_hour_phone__c: null,
        location__longitude__s: -72.439716,
        public_mailing_state__c: 'New York',
        facebook__c: null,
        aor_home_page_url__c: 'http://www.seatow.com/easternli',
        sfid: 'a0a37000002rYtSAAU',
        id: 28,
        _hc_lastop: null,
        _hc_err: null,
        employee_photo_url__c: null,
      },
    },
  ],
}

export const fetchAllMembershipsForAccountActiveEnrolled = {
  account: membershipAccount,
  memberships: [baseMembership],
}

export const fetchAllMembershipsForAccountMultipleMemberships = {
  account: membershipAccount,
  memberships: [
    baseMembership,
    {
      ...baseMembership,
      id: 6787,
      sfid: 'a007h0000042XmXAAV',
      membership_number__c: '4823046',
      heroku_external_id__c: 'membership-4823046',
      primary_membership__c: false,
    },
    {
      ...baseMembership,
      membership_type__c: 'Gold Card',
      id: 6788,
      sfid: 'a007h0000042XmXAAW',
      membership_number__c: '4823047',
      heroku_external_id__c: 'membership-4823047',
      primary_membership__c: false,
    },
  ],
}

export const fetchAllMembershipsForAccountActiveNotEnrolled = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      auto_renew__c: false,
    },
  ],
}

export const fetchAllMembershipsForAccountActiveSubsidized = {
  account: {
    ...membershipAccount,
    account_detail_type__c: 'Customer - Subsidized',
  },
  memberships: [baseMembership],
}

export const fetchAllMembershipsForAccountActiveNotEnrolledExpiring = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      auto_renew__c: false,
      membership_expiration_date__c: '2021-09-01',
    },
    {
      ...baseMembership,
      auto_renew__c: false,
      id: 6748,
      membership_status__c: 'Future Start',
      membership_effective_date__c: '2021-09-02 17:11:41',
      membership_expiration_date__c: '2022-10-10',
      membership_number__c: '2424110',
      sfid: 'a003700000AlpQPZWW',
    },
  ],
}

export const fetchAllMembershipsForAccountExpired = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Expired',
      membership_expiration_date__c: '2021-01-01',
    },
  ],
}

export const fetchAllMembershipsForAccountCancelled = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Cancelled',
    },
  ],
}

export const fetchAllMembershipsForAccountInactive = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Inactive',
    },
  ],
}

export const fetchAllMembershipsForAccountFutureEnrolled = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Future Start',
      membership_effective_date__c: '2021-09-02 17:11:41',
      membership_number__c: '2424110',
      sfid: 'a003700000AlpQPZWW',
    },
    {
      ...baseMembership,
      id: 6748,
      membership_status__c: 'Expired',
      membership_number__c: '2424133',
      sfid: 'a003700000AlpQPZXT',
    },
  ],
}

export const fetchAllMembershipsForAccountFutureNotEnrolled = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      auto_renew__c: false,
      membership_status__c: 'Future Start',
      membership_effective_date__c: '2021-09-01 17:11:41',
    },
  ],
}

export const fetchAllMembershipsForAccountActiveTrailerCareUnavailable = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_type__c: 'Professional',
    },
  ],
}

export const fetchAllMembershipsForAccountActiveTrailerCareSingleYearNotEnrolled =
{
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      trailer_care_type__c: null,
    },
  ],
}

export const fetchAllMembershipsForAccountActiveTrailerCareMultiYearNotEnrolled =
{
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      multiyear_product: true,
      trailer_care_type__c: null,
    },
  ],
}

export const fetchAllMembershipsForAccountExpiredTrailerCareSingleYearNotEnrolled =
{
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Expired',
      trailer_care_type__c: null,
    },
  ],
}

export const fetchAllMembershipsForAccountExpiredTrailerCareMultiYearNotEnrolled =
{
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Expired',
      multiyear_product: true,
      trailer_care_type__c: null,
    },
  ],
}

export const fetchAllMembershipsForAccountActiveTrailerCareSingleYearBasic = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      trailer_care_type__c: 'Trailer Care Marine',
    },
  ],
}

export const fetchAllMembershipsForAccountActiveTrailerCareMultiYearBasic = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      trailer_care_type__c: 'Trailer Care Marine',
      multiyear_product: true,
    },
  ],
}

export const fetchAllMembershipsForAccountExpiredTrailerCareSingleYearBasic = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Expired',
      trailer_care_type__c: 'Trailer Care Marine',
    },
  ],
}

export const fetchAllMembershipsForAccountExpiredTrailerCareMultiYearBasic = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Expired',
      trailer_care_type__c: 'Trailer Care Marine',
      multiyear_product: true,
    },
  ],
}

export const fetchAllMembershipsForAccountActiveTrailerCareSingleYearPlus = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_effective_date__c: '2021-08-31 17:11:41',
      membership_status__c: 'Future Start',
      trailer_care_type__c: 'Trailer Care Universal',
    },
  ],
}

export const fetchAllMembershipsForAccountActiveTrailerCareMultiYearPlus = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      trailer_care_type__c: 'Trailer Care Universal',
      multiyear_product: true,
    },
  ],
}

export const fetchAllMembershipsForAccountExpiredTrailerCareSingleYearPlus = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Expired',
      trailer_care_type__c: 'Trailer Care Universal',
    },
  ],
}

export const fetchAllMembershipsForAccountExpiredTrailerCareMultiYearPlus = {
  account: membershipAccount,
  memberships: [
    {
      ...baseMembership,
      membership_status__c: 'Expired',
      trailer_care_type__c: 'Trailer Care Universal',
      multiyear_product: true,
    },
  ],
}

export const fetchMembershipAndAorSuccess = {
  aor_details: membershipAor,
  membership: baseMembership,
}

// [ New Member Application ]------------------------------------------------
export const activationCodeDetails = {
  autoRenewRequired: false,
  name: '02432B43',
  description: 'Activation Code - $100.00 Off Membership',
  dollarsOff: 100,
  type: 'activation',
  id: 'a0bVE0000016dUPYAY',
  promo_legal: '',
  promotion_details: '',
  related_product: {
    membership_type__c: 'Other',
    family: 'Gift Card',
    name: 'Gift Card / Kit Activation',
    product_payout_term__c: 1,
    isdeleted: false,
    systemmodstamp: '2024-02-01 17:29:52',
    value_time_in_days__c: 0,
    createddate: '2017-08-24 18:11:59',
    productcode: 'GIFT-CRD-ACTIVATE',
    sfid: '01t37000001SPpGAAW',
    id: 24,
    _hc_lastop: null,
    _hc_err: null,
  },
  valid_on_business_type__c: 'New;Reacquired',
  valid_on_membership__c: 'Lake Card;Commercial Card;Professional;Mariner Card',
  usage_rules__c: 'ARB Required',
}

export const membershipApplicationAccount = {
  account: membershipAccount,
  contact: first(fetchContacts),
  membership__c: baseMembership,
}

export const productPrices = {
  Membership: {
    'Universal Membership Exchange': 0.0,
    'CANADA - Gold Card': 179.0,
    'Complete Care Card - 3 Year': 432.0,
    'Lake Card': 119.0,
    'Commercial Card': 179.0,
    'Complete Care Card - 5 Year': 675.0,
    'Comp Commercial Card': 0.0,
    'Complete Care Card - 1 Year': 144.0,
    'Comp Gold Card': 0.0,
    'Lake Fleet Card': 299.0,
    'Fleet Card': 599.0,
    'CANADA - Gold Card - 2 Year': 199.0,
    'Comp Lake Card': 0.0,
    'Full Marina - Lake 300': 35700.0,
    'Complete Care Card - 2 Year': 288.0,
    'Boat House 3-Year Gold': 417.0,
    'Professional Mariner Card': 365.0,
    'Comp Professional Mariner Card': 0.0,
    'Membership - 95 Days': 0.0,
    'Membership - 60 Days': 0.0,
    'Membership - 30 Days': 0.0,
    'Formula 5-Year Gold': 667.0,
    'Gold Card 10 Year': 1790.0,
    'Upgrade - Gold Card': 60.0,
    'Gold Card 3 Year': 537.0,
    'Gold Card': 179.0,
    'Gold Card 2 Year': 358.0,
    'Time Transfer - Commercial Card': 179.0,
    'Time Transfer - Gold Card': 179.0,
    'Time Transfer - Lake Card': 119.0,
    'Time Transfer - Professional Mariner Card': 365.0,
    'Time Transfer - Formula 5 Year': 667.0,
    'Time Transfer - Gold Card 3 Year': 667.0,
    'Lake to Gold Upgrade': 80.0,
  },
  None: {
    Tax: 179.0,
    'Member Adjust End': 0.0,
    'Member Flex Start': 0.0,
    'Refund Product': 0.0,
    'Add Member Days - Variable': 0.0,
    'Canadian Package Discount': -30.0,
    'Over Payment': 0.0,
    'Collection Withholding': 0.0,
    'Member Adjust Start': 0.0,
    'Membership Off Hold': 0.0,
    'Membership Reactivate': 0.0,
    'Payment Credit': 0.0,
  },
  'Trailer Care': {
    'Trailer Care Marine Clone': 14.0,
    'Universal Trailer Care Exchange': 0.0,
    'Comp Trailer Care Marine': 0.0,
    'Trailer Care Marine - 2 Year': 28.0,
    'Trailer Care Plus': 29.95,
    'Trailer Care': 14.0,
    'Promo Trailer Care Marine': 0.0,
    'Trailer Care Marine - 3 Year': 42.0,
    'Trailer Care Marine - 10 Year': 140.0,
    'Upgrade - Trailer Care Universal': 15.95,
    'Trailer Care Plus Upgrade': 15.95,
  },
  Promotion: {
    'Promo 0 Value Tracking Only': 0,
    'Promo 30 Days / 1 Month': 0.0,
    'Promo 365 Days': 0.0,
    'Promo 60 Days / 2 Months': 0.0,
    'Promo 95 Days': 0.0,
    'Promo Code Dollar Variable': 174.0,
    'Promo Code Percentage Variable': 0.0,
    'Promo 90 Days / 3 Months': 0.0,
    'Referral Credit': 0.0,
  },
  'Gift Card': {
    'Gift Card / Kit Activation': 0.0,
  },
  Donation: {
    'Sea Tow Foundation Donation': 0.0,
  },
}

export const promoCodeDetails = {
  promo_end_date__c: '2023-12-31',
  promo_standardized__c: 'AUTORENEW20',
  valid_on_business_type__c: 'Both',
  description__c: '$20 off w/Active AutoRenew',
  related_product__c: '01t37000001DyVwAAK',
  promo_start_date__c: '2019-03-13',
  name: 'AUTORENEW20',
  title__c: '$20 off Membership with Auto Renewal',
  promotion_type__c: 'Dollar_Value_Promotion',
  heroku_external_id__c: null,
  isdeleted: false,
  systemmodstamp: '2021-05-12 19:13:52',
  related_program__c: 'a0f37000001jjpiAAA',
  value_time_in_days_calculated__c: 0.0,
  ongoing_promo_recurrences__c: null,
  ongoing_promo_type__c: 'Ongoing with ARB',
  promo_carries_on_renewal__c: true,
  value_time_in_days__c: 0.0,
  value_discount_in_dollars__c: 20.0,
  createddate: '2019-03-13 14:25:18',
  promotion_details__c:
    '$20 off of new and renewing membership with the selection of the Auto Renewal option.',
  value_discount_in_percentage__c: 0.0,
  sfid: 'a021G00000oaAcoQAE',
  id: 435,
  _hc_lastop: null,
  _hc_err: null,
}

// [ Programs Participant Application ]------------------------------------------------
export const applyAsNewPartnerNoAuth0Check = {
  auth0_status: 'NO_AUTH0_CHECK_PERFORMED',
}

export const applyAsNewPartnerAuth0Account = {
  auth0_status: 'AUTH0_ACCOUNT_FOUND',
}

export const applyAsNewPartnerNoAuth0Account = {
  auth0_email: 'amazon-seatow@amazon.com',
  auth0_status: 'AUTH0_ACCOUNT_NOT_FOUND',
  account_uuid: '9234e5a3-0d5a-42a8-87fd-2e3a85ea526a',
}

// [ Programs ]-------------------------------------------------------
export const fetchAllProgramsForPartner = {
  savings_club: {
    active: [
      {
        options_for_online_redemption__c: null,
        in_person_redemption__c: true,
        primary_category__c: 'Dining',
        type_of_offer__c: 'Dollars Off',
        offer_description__c:
          'This is an example of an active in person savings club offer',
        name: 'SCO-0000000000',
        online_redemption__c: false,
        partner_account__c: '0011G00000ljBXRQA2',
        offer_status__c: 'Active',
        ownerid: '005370000040ia5AAA',
        heroku_external_id__c: null,
        isdeleted: false,
        systemmodstamp: '2021-09-16 17:52:56',
        online_promotional_url__c: null,
        approval_status__c: 'Approved',
        createddate: '2021-09-16 17:52:56',
        options_for_in_store_redemption__c: 'Present Membership Card',
        type_of_offer_other__c: null,
        secondary_category__c: 'Charters, Rentals & Travel;Dining',
        online_promo_code__c: null,
        sfid: 'a2x7h000000LVpuAAG',
        id: 1,
        _hc_lastop: 'SYNCED',
        _hc_err: null,
      },
      {
        options_for_online_redemption__c: null,
        in_person_redemption__c: false,
        primary_category__c: 'Retail',
        type_of_offer__c: 'Other',
        offer_description__c: 'Example of pending approval offer',
        name: 'SCO-0000000004',
        online_redemption__c: true,
        partner_account__c: '0011G00000ljBXRQA2',
        offer_status__c: 'Active',
        ownerid: '005370000040ia5AAA',
        heroku_external_id__c: null,
        isdeleted: false,
        systemmodstamp: '2021-09-16 18:17:05',
        online_promotional_url__c: null,
        approval_status__c: 'Approved',
        createddate: '2021-09-16 18:17:05',
        options_for_in_store_redemption__c: null,
        type_of_offer_other__c: 'AAAAAexample',
        secondary_category__c: 'Services & Repair',
        online_promo_code__c: null,
        sfid: 'a2x7h000000LVqOAAW',
        id: 5,
        _hc_lastop: 'SYNCED',
        _hc_err: null,
      },
    ],
    prior: [
      {
        options_for_online_redemption__c: null,
        in_person_redemption__c: false,
        primary_category__c: 'Dining',
        type_of_offer__c: 'Percentage Off',
        offer_description__c: 'Example of inactive offer',
        name: 'SCO-0000000001',
        online_redemption__c: false,
        partner_account__c: '0011G00000ljBXRQA2',
        offer_status__c: 'Inactive',
        ownerid: '005370000040ia5AAA',
        heroku_external_id__c: null,
        isdeleted: false,
        systemmodstamp: '2021-09-16 18:04:59',
        online_promotional_url__c: null,
        approval_status__c: 'Denied',
        createddate: '2021-09-16 18:04:59',
        options_for_in_store_redemption__c: null,
        type_of_offer_other__c: null,
        secondary_category__c: 'Apparel',
        online_promo_code__c: null,
        sfid: 'a2x7h000000LVq9AAG',
        id: 2,
        _hc_lastop: 'SYNCED',
        _hc_err: null,
      },
    ],
    awaiting_approval: [
      {
        options_for_online_redemption__c: null,
        in_person_redemption__c: false,
        primary_category__c: 'Entertainment',
        type_of_offer__c: 'Rebate',
        offer_description__c: 'Example of pending approval offer',
        name: 'SCO-0000000002',
        online_redemption__c: false,
        partner_account__c: '0011G00000ljBXRQA2',
        offer_status__c: 'Inactive',
        ownerid: '005370000040ia5AAA',
        heroku_external_id__c: null,
        isdeleted: false,
        systemmodstamp: '2021-09-16 18:05:56',
        online_promotional_url__c: null,
        approval_status__c: 'Awaiting Approval',
        createddate: '2021-09-16 18:05:56',
        options_for_in_store_redemption__c: null,
        type_of_offer_other__c: null,
        secondary_category__c: null,
        online_promo_code__c: null,
        sfid: 'a2x7h000000LVqEAAW',
        id: 3,
        _hc_lastop: 'SYNCED',
        _hc_err: null,
      },
      {
        options_for_online_redemption__c: null,
        in_person_redemption__c: true,
        primary_category__c: 'Equipment & Supplies',
        type_of_offer__c: 'Percentage Off',
        offer_description__c: 'Example of pending approval offer',
        name: 'SCO-0000000003',
        online_redemption__c: false,
        partner_account__c: '0011G00000ljBXRQA2',
        offer_status__c: 'Inactive',
        ownerid: '005370000040ia5AAA',
        heroku_external_id__c: null,
        isdeleted: false,
        systemmodstamp: '2021-09-16 18:16:49',
        online_promotional_url__c: null,
        approval_status__c: 'Awaiting Approval',
        createddate: '2021-09-16 18:16:49',
        options_for_in_store_redemption__c: null,
        type_of_offer_other__c: null,
        secondary_category__c: 'Dining',
        online_promo_code__c: null,
        sfid: 'a2x7h000000LVqJAAW',
        id: 4,
        _hc_lastop: 'SYNCED',
        _hc_err: null,
      },
    ],
  },
}

// [ Savings Club ]------------------------------------------------------------
const baseOffer = {
  approval_status__c: 'Approved',
  company_logo__c:
    'https://images.vexels.com/media/users/3/228543/isolated/lists/88ea38c3883fb1a74df12e3aaae26bd0-ferry-vintage-circle-logo.png',
  company_name__c: 'Boating Express',
  dollars_off__c: null,
  featured__c: true,
  heroku_external_id__c: null,
  in_person_redemption__c: false,
  isdeleted: false,
  locality_of_offer__c: 'National',
  name: 'SCO-0000000007',
  offer_description__c:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  offer_status__c: 'Active',
  online_promo_code__c: null,
  online_promotional_url__c: null,
  online_redemption__c: true,
  options_for_in_store_redemption__c: null,
  options_for_online_redemption__c: null,
  partner_account__c: '0017h00000ctHI1AAM',
  percent_off__c: 5,
  primary_category__c: 'Fuel',
  secondary_category__c: null,
  type_of_offer__c: 'Percentage Off',
  type_of_offer_other__c: null,

  _hc_err: null,
  _hc_lastop: 'SYNCED',
  createddate: '2021-09-27 15:34:31',
  id: 8,
  ownerid: '005370000040ia5AAA',
  sfid: 'a2x7h000000LqBVAA0',
  systemmodstamp: '2021-09-27 15:36:50',
}

export const featuredOffers = [
  baseOffer,
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    distance_from_zip: 3.2222,
    id: 9,
    in_person_redemption__c: true,
    locality_of_offer__c: 'Local',
    primary_category__c: 'Equipment & Supplies',
    secondary_category__c: 'Fuel',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 10,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 11,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 12,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 13,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 14,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 15,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 16,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 17,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 18,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-1',
    id: 19,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 20,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 21,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 22,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 23,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 24,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 25,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 26,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 27,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 28,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 29,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 30,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-2',
    id: 31,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-3',
    id: 32,
    primary_category__c: 'Equipment & Supplies',
  },
  {
    ...baseOffer,
    company_name__c: 'REI-page-3',
    id: 33,
    primary_category__c: 'Equipment & Supplies',
  },
]

export const fetchOfferDetail = {
  account: fetchPartnerAccount,
  offer: baseOffer,
  additional_offers: [
    {
      ...baseOffer,
      company_name__c: 'REI-page-2',
      id: 31,
      primary_category__c: 'Equipment & Supplies',
    },
    {
      ...baseOffer,
      company_name__c: 'REI-page-3',
      id: 32,
      primary_category__c: 'Equipment & Supplies',
    },
  ],
}

// [ Savings Offers ]----------------------------------------------------------
export const fetchOfferCard = {
  _hc_err: null,
  _hc_lastop: 'SYNCED',
  approval_status__c: 'Approved',
  company_name__c: 'Boating Express',
  company_logo__c:
    'https://images.vexels.com/media/users/3/228543/isolated/lists/88ea38c3883fb1a74df12e3aaae26bd0-ferry-vintage-circle-logo.png',
  createddate: '2021-09-27 15:34:31',
  dollars_off__c: null,
  featured__c: true,
  heroku_external_id__c: null,
  id: 8,
  in_person_redemption__c: false,
  isdeleted: false,
  locality_of_offer__c: 'National',
  name: 'SCO-0000000007',
  offer_description__c:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  offer_status__c: 'Active',
  online_promo_code__c: null,
  online_promotional_url__c: null,
  online_redemption__c: true,
  options_for_in_store_redemption__c: null,
  options_for_online_redemption__c: null,
  ownerid: '005370000040ia5AAA',
  partner_account__c: '0017h00000ctHI1AAM',
  percent_off__c: 5,
  primary_category__c: 'Fuel',
  secondary_category__c: null,
  sfid: 'a2x7h000000LqBVAA0',
  systemmodstamp: '2021-09-27 15:36:50',
  type_of_offer__c: 'Percentage Off',
  type_of_offer_other__c: null,
}

export const fetchRandomOffers = [
  {
    _hc_err: null,
    _hc_lastop: 'SYNCED',
    approval_status__c: 'Approved',
    company_logo__c: null,
    company_name__c: 'Boat Company #1',
    createddate: '2021-09-27 15:34:31',
    dollars_off__c: 5,
    featured__c: true,
    heroku_external_id__c: null,
    id: 8,
    in_person_redemption__c: false,
    isdeleted: false,
    locality_of_offer__c: 'National',
    name: 'SCO-0000000007',
    offer_description__c:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    offer_status__c: 'Active',
    online_promo_code__c: null,
    online_promotional_url__c: null,
    online_redemption__c: true,
    options_for_in_store_redemption__c: null,
    options_for_online_redemption__c: null,
    ownerid: '005370000040ia5AAA',
    partner_account__c: '0017h00000ctHI1AAM',
    percent_off__c: null,
    primary_category__c: 'Fuel',
    secondary_category__c: null,
    sfid: 'a2x7h000000LqBVAA0',
    systemmodstamp: '2021-09-27 15:36:50',
    type_of_offer__c: 'Dollars Off',
    type_of_offer_other__c: null,
  },
  {
    _hc_err: null,
    _hc_lastop: 'SYNCED',
    approval_status__c: 'Approved',
    createddate: '2021-09-27 15:34:04',
    company_logo__c:
      'https://static.macupdate.com/products/49953/m/boat-watch-logo.webp?v=1576051793',
    company_name__c: 'Boat Company #2',
    dollars_off__c: 5,
    featured__c: true,
    heroku_external_id__c: null,
    id: 7,
    in_person_redemption__c: true,
    isdeleted: false,
    locality_of_offer__c: 'National',
    name: 'SCO-0000000006',
    offer_description__c:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    offer_status__c: 'Active',
    online_promo_code__c: null,
    online_promotional_url__c: null,
    online_redemption__c: true,
    options_for_in_store_redemption__c: null,
    options_for_online_redemption__c: null,
    ownerid: '005370000040ia5AAA',
    partner_account__c: '0017h00000ctHIVAA2',
    percent_off__c: null,
    primary_category__c: 'Charters, Rentals & Travel',
    secondary_category__c: null,
    sfid: 'a2x7h000000LqBQAA0',
    systemmodstamp: '2021-09-27 15:36:56',
    type_of_offer__c: 'Dollars Off',
    type_of_offer_other__c: null,
  },
  {
    _hc_err: null,
    _hc_lastop: 'SYNCED',
    approval_status__c: 'Approved',
    createddate: '2021-09-27 15:35:00',
    company_logo__c:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuCh4L-m3GuYNIlrpFSMI6V31r3Ok187Dflw&usqp=CAU',
    company_name__c: 'Boat Company #3',
    dollars_off__c: null,
    featured__c: false,
    heroku_external_id__c: null,
    id: 9,
    in_person_redemption__c: false,
    isdeleted: false,
    locality_of_offer__c: 'National',
    name: 'SCO-0000000008',
    offer_description__c:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    offer_status__c: 'Active',
    online_promo_code__c: null,
    online_promotional_url__c: null,
    online_redemption__c: true,
    options_for_in_store_redemption__c: null,
    options_for_online_redemption__c: null,
    ownerid: '005370000040ia5AAA',
    partner_account__c: '0017h00000ctHIVAA2',
    percent_off__c: 2,
    primary_category__c: 'Charters, Rentals & Travel',
    secondary_category__c: null,
    sfid: 'a2x7h000000LqBaAAK',
    systemmodstamp: '2021-09-27 15:37:15',
    type_of_offer__c: 'Percentage Off',
    type_of_offer_other__c: null,
  },
]

// [ Referral ]------------------------------------------------
export const fetchReferralForNewMember = {
  _hc_err: null,
  _hc_lastop: 'SYNCED',
  applied_to_order__c: null,
  createddate: 'Tue, 28 Nov 2023 16:13:56 GMT',
  credit_amount_applied__c: 0.0,
  display_text: '4937645 - Expired - DO NOT CHANGE/MODIFY',
  heroku_external_id__c: null,
  id: 1,
  isdeleted: false,
  member_referred__c: null,
  member_referred__r__heroku_external_id__c: null,
  member_referred_email__c: 'pgillan@seatow.com',
  member_referred_first_name__c: 'Test',
  member_referred_last_name__c: 'Test2',
  member_referred_status__c: 'Pending',
  name: 'RFID-0000135814',
  new_member_create_date__c: null,
  partial_credit__c: false,
  raf_credit_used_date__c: null,
  referral_count_accounts__c: 0.0,
  referral_count_leads__c: 0.0,
  referral_name_search__c: null,
  referring_member__c: '001D200000yUgROIA0',
  referring_member_account_id__c: '001D200000yUgROIA0',
  referring_member_credit__c: 0.0,
  referring_member_name__c: 'Expired - DO NOT CHANGE/MODIFY',
  referring_member_number__c: '4937645',
  referring_member_type__c: 'General',
  related_invoice__c: null,
  related_invoice__r__heroku_external_id__c: null,
  sfid: 'a0gD2000002RT2fIAG',
  systemmodstamp: 'Tue, 28 Nov 2023 16:13:57 GMT',
}
