import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { wpApi } from 'api'
import * as actions from 'member-actions'
import { selectors } from 'member-reducer'
import { selectors as membershipsSelector } from 'memberships/reducer'
import { ExpiredMembershipWarningModal } from 'member-portal/components'
import { SiteMetadata } from 'components'
import { Spinner } from 'lp-components'
import { isEmpty } from 'lodash'
import { AllMembershipsType, MEMBERSHIP_STATUS } from 'types'
import { makePath, makeMembershipsPath, PATH } from 'config'
import { getPrimaryMembership } from 'utils'

const propTypes = {
  allMemberships: AllMembershipsType,
  children: PropTypes.node.isRequired,
  setVideoDetails: PropTypes.func,
  videoDetails: PropTypes.object,
}

function GatedContentLayout({
  allMemberships = {},
  children,
  setVideoDetails,
  videoDetails = {},
}) {
  const isOpen = useRef(false)
  const { videoID } = useParams()
  const { membershipStatus, sfid } = getPrimaryMembership(allMemberships)

  const handleCancel = () => {
    window.location.replace(makePath(PATH.SEATOW, PATH.VIDEOS))
  }

  useEffect(() => {
    if (isEmpty(videoDetails)) {
      const getVideoDetails = async () => {
        const video = await wpApi(`videos/${videoID}`)
        setVideoDetails(video)
      }

      getVideoDetails()
    }
  }, [videoDetails, videoID])

  if (!videoDetails.video_uid) return <Spinner />

  const { id, is_private, title, short_description, keywords } = videoDetails

  // If the video is private, then first redirect and authenticate the user.
  // If the member is expired, persist the Expired Membership modal. If the
  // user is on a Do Not Renew or Cancelled status, redirect them back to
  // Seatow.com. Otherwise, render the gated content.
  if (is_private) {
    if (!membershipStatus) {
      return (
        <Redirect
          to={makeMembershipsPath(PATH.GATED_CONTENT, PATH.VIDEOS, `/${id}`)}
        />
      )
    }

    if (membershipStatus === MEMBERSHIP_STATUS.EXPIRED && !isOpen.current) {
      isOpen.current = true
    }

    if (
      [MEMBERSHIP_STATUS.DO_NOT_RENEW, MEMBERSHIP_STATUS.CANCELLED].includes(
        membershipStatus,
      )
    ) {
      window.location.replace(PATH.SEATOW)
      return null
    }
  }

  return (
    <>
      {!is_private && (
        <SiteMetadata
          metaTitle={title}
          description={short_description}
          keywords={keywords}
        />
      )}
      <ExpiredMembershipWarningModal
        handleCancel={handleCancel}
        isOpen={isOpen.current}
        sfid={sfid}
      />
      <div className="gated-content">{children}</div>
    </>
  )
}

GatedContentLayout.propTypes = propTypes

function mapStateToProps(state) {
  return {
    allMemberships: membershipsSelector.allMemberships(state),
    videoDetails: selectors.videoDetails(state),
  }
}

const mapDispatchToProps = {
  setVideoDetails: actions.setVideoDetails,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GatedContentLayout,
)
