import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { combineReducers } from 'redux'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import {
  reducer as membershipCardReducer,
  reducerKey as membershipCardReducerKey,
} from './membership_card'
import { reducer as boatsReducer, reducerKey as boatsReducerKey } from './boats'
import {
  reducer as renewalReducer,
  reducerKey as renewalReducerKey,
} from './renewal'
import * as actions from './actions'
import * as apiActions from 'api-actions'
import { selectors as memberSelectors } from 'member-reducer'
import { BOAT_STATUS } from 'types'
import { findPrimaryBoat } from 'utils'

const reducerKey = 'memberships'
const slice = 'root.memberPortal.memberships.allMemberships'

const initialState = {}

const membershipsReducer = handleActions(
  {
    [actions.clearAllMemberships]: unsetState('allMemberships'),
    [apiActions.fetchAllMembershipsForAccount]: setOnSuccess('allMemberships'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  allMemberships: select('allMemberships'),
}

selectors.account = createSelector(
  [selectors.allMemberships],
  (allMemberships) => {
    if (!allMemberships) return
    return allMemberships.account
  }
)

selectors.primaryBoat = createSelector(
  [memberSelectors.membership],
  (membership) => {
    if (!membership) return
    return findPrimaryBoat(membership.boats)
  }
)

selectors.additionalBoats = createSelector(
  [memberSelectors.membership],
  (membership) => {
    if (!membership) return
    return membership.boats.filter(
      ({ boat_status__c, primary_boat__c }) =>
        boat_status__c === BOAT_STATUS.ACTIVE && primary_boat__c === false
    )
  }
)

const reducer = combineReducers({
  allMemberships: membershipsReducer,
  [boatsReducerKey]: boatsReducer,
  [membershipCardReducerKey]: membershipCardReducer,
  [renewalReducerKey]: renewalReducer,
})

export { reducer, selectors, reducerKey }