import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { useLocation } from 'react-router-dom'
import * as memberActions from 'member-actions'
import { selectors as memberSelectors } from 'member-reducer'
import { Button, Modal } from 'lp-components'
import { makeMemberApplicationPath, PATH } from 'config'
import { useClearDiscountCodeAndRedirect, useDiscountCodeHandler } from 'utils'

const propTypes = {
  clearMembershipSelectionAndLS: PropTypes.func.isRequired,
  discountCodeDetails: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  isTrialMembershipsActive: PropTypes.bool.isRequired,
  promoCodeIsTrial: PropTypes.bool.isRequired,
  toggleModalOpen: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
}

function AutoRenewalWarningModal({
  clearMembershipSelectionAndLS,
  discountCodeDetails,
  isOpen,
  isTrialMembershipsActive,
  promoCodeIsTrial,
  toggleModalOpen,
  updateFormValues,
}) {
  const location = useLocation()
  const handleDiscountCode = useDiscountCodeHandler()
  const clearDiscountCodeAndRedirect = useClearDiscountCodeAndRedirect()

  const cancelButtonText = promoCodeIsTrial
    ? 'Continue with trial activation'
    : 'Cancel'
  const unenrollButtonText = promoCodeIsTrial
    ? 'Exit activation'
    : 'Yes, Opt Out'

  const exitTrialActivation = () => {
    clearMembershipSelectionAndLS()
    clearDiscountCodeAndRedirect(makeMemberApplicationPath(PATH.ROOT))
    toggleModalOpen()
  }

  const handleOptOut = () => {
    handleDiscountCode({
      basePath: location.pathname,
      discountCodeDetails,
    })
    toggleModalOpen()
  }

  const { autoRenewRequired, type } = discountCodeDetails || {}

  const getModalMessage = () => {
    if (promoCodeIsTrial && isTrialMembershipsActive) {
      return 'This Trial membership requires automatic renewal. If you choose to unenroll, you will not be able to qualify and proceed. In order to continue to your trial membership, you will need to select automatic renewal.'
    }

    if (autoRenewRequired) {
      return `This ${type} code requires selection of automatic renewal. If you choose to opt out of automatic renewal, the ${type} code will be removed.`
    }

    return 'By opting out of Automatic Renewal, your membership will expire at the end of the term unless you renew prior to that date. Automatic renewal is recommended so there is no lapse in membership.'
  }

  return (
    <>
      {isOpen && (
        <Modal preventClose={true}>
          <p>{getModalMessage()}</p>
          <div className="button-group">
            <Button
              onClick={() => {
                toggleModalOpen()
                updateFormValues()
              }}
              className="button-primary-outline"
            >
              {isTrialMembershipsActive ? cancelButtonText : 'Cancel'}
            </Button>
            <Button
              onClick={() => {
                promoCodeIsTrial && isTrialMembershipsActive
                  ? exitTrialActivation()
                  : handleOptOut()
              }}
              className="button-warn"
            >
              {isTrialMembershipsActive ? unenrollButtonText : 'Yes, Opt Out'}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
    promoCodeIsTrial: memberSelectors.promoCodeIsTrial(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearMembershipSelectionAndLS: () =>
      dispatch(memberActions.clearMembershipSelectionAndLS()),
    updateFormValues: function () {
      dispatch(change('PaymentForm', 'autoRenewalAgreement', true))
    },
  }
}

AutoRenewalWarningModal.propTypes = propTypes

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AutoRenewalWarningModal,
)
