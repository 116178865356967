import React from 'react'
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useLocation,
} from 'react-router-dom'
import Layout from './Layout'
import { AuthenticatedRoutes, Logout, UnauthenticatedLayout } from 'components'
import { MemberAccountProvider, ProductPricesProvider } from './components'
import { Routes as MemberApplicationRoutes } from './member_application'
import { Routes as AccountRoutes } from './account'
import { Routes as RegistrationRoutes } from './registration'
import { Routes as MembershipCardViewPrintRoutes } from './membership_card_view_print'
import { Routes as MembershipRoutes } from './memberships'
import { Routes as ReferralsRoutes } from './referrals'
import { Routes as SavingsClubRoutes } from './savings_club'
import { Routes as SeaTowAsAGiftRoutes } from './sea_tow_as_a_gift'
import { Routes as MediaRoutes } from './media'
import { PATH, makePath, makeMembershipsPath } from 'config'

const propTypes = {}

function Routes() {
  const { path } = useRouteMatch()
  const location = useLocation()

  return (
    <Switch>
      {/* Unauthenticated routes *without* a layout... */}
      <Route path={makePath(path, PATH.REGISTRATION)}>
        <RegistrationRoutes />
      </Route>

      <Route>
        <ProductPricesProvider>
          <Switch>
            {/* Unauthenticated routes *with* a layout... */}
            <Route path={makePath(path, PATH.MEMBER_APPLICATION)}>
              <UnauthenticatedLayout>
                <MemberApplicationRoutes />
              </UnauthenticatedLayout>
            </Route>
            <Route path={makePath(path, PATH.SEA_TOW_AS_A_GIFT)}>
              <UnauthenticatedLayout>
                <SeaTowAsAGiftRoutes />
              </UnauthenticatedLayout>
            </Route>
            <Route path={makePath(path, PATH.MEDIA)}>
              <UnauthenticatedLayout>
                <MediaRoutes />
              </UnauthenticatedLayout>
            </Route>


            {/* Authenticated routes... */}
            <Route>
              <AuthenticatedRoutes>
                <MemberAccountProvider>
                  <Switch>
                    {/* Authenticated routes *without* a layout... */}
                    <Route
                      path={makePath(path, PATH.MEMBERSHIP_CARD_VIEW_PRINT)}
                    >
                      <MembershipCardViewPrintRoutes />
                    </Route>
                    <Route
                      path={makePath(path, PATH.RENEW_MEMBERSHIP)}
                      render={() => (
                        <Redirect
                          to={{
                            ...location,
                            pathname: makeMembershipsPath(
                              PATH.SELECT_YOUR_MEMBERSHIP,
                              PATH.RENEW_MEMBERSHIP
                            ),
                          }}
                        />
                      )}
                    />

                    {/* Authenticated routes *with* a layout... */}
                    <Route>
                      <Layout>
                        <Switch>
                          <Route path={makePath(path, PATH.ACCOUNT)}>
                            <AccountRoutes />
                          </Route>
                          <Route path={makePath(path, PATH.LOGOUT)}>
                            <Logout />
                          </Route>
                          <Route path={makePath(path, PATH.MEMBERSHIPS)}>
                            <MembershipRoutes />
                          </Route>
                          <Route path={makePath(path, PATH.REFERRALS)}>
                            <ReferralsRoutes />
                          </Route>
                          <Route path={makePath(path, PATH.SAVINGS_CLUB)}>
                            <SavingsClubRoutes />
                          </Route>
                          <Redirect
                            path="*"
                            to={makePath(path, PATH.MEMBERSHIPS)}
                          />
                        </Switch>
                      </Layout>
                    </Route>
                  </Switch>
                </MemberAccountProvider>
              </AuthenticatedRoutes>
            </Route>
          </Switch>
        </ProductPricesProvider>
      </Route>
    </Switch>
  )
}

Routes.propTypes = propTypes

export default Routes
