import * as productionApiActions from './productionApiActions'
import * as testApiActions from './testApiActions'
import { usingTestApi } from 'config'
import { createStubRequest } from 'lp-redux-api'

const actions = usingTestApi() ? testApiActions : productionApiActions

// Always use the production version of these APIs...
export const autoCompleteAddress = productionApiActions.autoCompleteAddress
export const fetchPlaceDetails = productionApiActions.fetchPlaceDetails

// Allow toggle between the test and production version of these APIs...
export const addTrailerCare = actions.addTrailerCare
export const applyAsAParticipant = actions.applyAsAParticipant
export const applyForMembershipKit = actions.applyForMembershipKit
export const applyForSeaCare = actions.applyForSeaCare
export const addPaymentToOpportunity = actions.addPaymentToOpportunity
export const prepareOpportunityAndOLIs = actions.prepareOpportunityAndOLIs
export const chargeMembershipUpgrade = actions.chargeMembershipUpgrade
export const createAndLinkAccount = actions.createAndLinkAccount
export const createContact = actions.createContact
export const createBoat = actions.createBoat
export const createMembershipApplicationAccount =
  actions.createMembershipApplicationAccount
export const updateMembershipApplicationAccount =
  actions.updateMembershipApplicationAccount
export const updateMembershipAssociatedAccount =
  actions.updateMembershipAssociatedAccount
export const createOffer = actions.createOffer
export const createReferral = actions.createReferral
export const deleteBoat = actions.deleteBoat
export const deleteContact = actions.deleteContact
export const enrollAutoRenewal = actions.enrollAutoRenewal
export const fetchAccount = actions.fetchAccount
export const fetchActivationCodeDetails = actions.fetchActivationCodeDetails
export const fetchAllMembershipsForAccount =
  actions.fetchAllMembershipsForAccount
export const fetchAllProgramsForPartner = actions.fetchAllProgramsForPartner
export const fetchAor = actions.fetchAor
export const fetchContacts = actions.fetchContacts
export const fetchCoordinates = actions.fetchCoordinates
export const fetchMembershipApplicationAccount =
  actions.fetchMembershipApplicationAccount
export const fetchMembershipAndAor = actions.fetchMembershipAndAor
export const fetchMembershipCardOrderStatus =
  actions.fetchMembershipCardOrderStatus
export const fetchOfferDetail = actions.fetchOfferDetail
export const fetchProductPrices = actions.fetchProductPrices
export const fetchPromoCodeDetails = actions.fetchPromoCodeDetails
export const fetchRandomOffers = actions.fetchRandomOffers
export const fetchReferral = actions.fetchReferral
export const fetchVideoDetails = actions.fetchVideoDetails
export const swapMetadata = actions.swapMetadata
export const requestCardReprint = actions.requestCardReprint
export const requestPasswordChange = actions.requestPasswordChange
export const reverseGeoAddress = actions.reverseGeoAddress
export const searchSavingsClub = actions.searchSavingsClub
export const sendSeaTowGift = actions.sendSeaTowGift
export const setOldInactiveAndSetNewPrimary =
  actions.setOldInactiveAndSetNewPrimary
export const unenrollAutoRenewal = actions.unenrollAutoRenewal
export const updateAccount = actions.updateAccount
export const updateARBCreditCard = actions.updateARBCreditCard
export const updateBillingAddress = actions.updateBillingAddress
export const updateBoat = actions.updateBoat
export const updateContact = actions.updateContact
export const updateOffer = actions.updateOffer
export const uploadLogo = actions.uploadLogo
export const verifyMembership = actions.verifyMembership
export const verifyUniquePrimaryEmail = actions.verifyUniquePrimaryEmail
export const proceedToUpgrade = createStubRequest('PROCEED_TO_UPGRADE')
