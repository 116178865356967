import React from 'react'
import AuthContext from './AuthContext'
import { FEATURES, LOGIN_EXPERIENCE_TYPE } from 'config'
import { isFeatureEnabled, parseQuery } from 'utils'
import useSeatowService from './useSeatowService'
import useAuth0Service from './useAuth0Service'
import { useLocation } from 'react-router-dom'
import {
  getLoginExperience,
  setLoginExperience,
} from 'components/auth/authStorage'

const AuthProvider = ({ children }) => {
  const isCustomAuthEnabled = isFeatureEnabled(FEATURES.CUSTOM_LOGIN)
  const { search } = useLocation()
  const { loginExperience } = parseQuery(search)

  if (loginExperience) {
    setLoginExperience(loginExperience.toLowerCase())
  }

  const storedLoginExperience = getLoginExperience()
  const isNewLogin = storedLoginExperience === LOGIN_EXPERIENCE_TYPE.NEW
  const shouldUseCustomAuth = isCustomAuthEnabled && isNewLogin

  const authState = shouldUseCustomAuth ? useSeatowService() : useAuth0Service()

  const value = {
    ...authState,
    shouldUseCustomAuth,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
