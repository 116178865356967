/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Redirect, useLocation } from 'react-router-dom'
import { useAuth } from 'components/auth'
import { AccountProvider } from 'global-account/components'
import { PATH, USER_METADATA, makeMemberPortalPath } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
}

function MemberAccountProvider({ children }) {
  const { user } = useAuth()
  const memberAccountId = user[USER_METADATA.MEMBER_ACCOUNT_ID]

  if (!memberAccountId) {
    // In this scenario, no membership metadata exists in Auth0. This happens in
    // two cases:
    //   1. An unbound member—someone who has a membership number and
    //   expiration date but no associated memberAccount ID. This typically 
    //   occurs when a new member completes the application process and is 
    //   directed here.
    //   2. An existing member who has never logged into the Member Portal 
    //   before and manually executed the "Sign Up" flow.
    //
    // For unbound members, we check if `state` contains a membership number and
    // expiration date, in which case they are redirected to the new member
    // registration process. Otherwise, if no state and no memberAccount ID
    // exist, we assume they are an existing member who never signed up and
    // send them to the existing member flow.
    //
    // This logic is confined for Auth0-based logins. With the new Sea Tow
    // login experience, unbound members are handled directly within their
    // custom login app.
    const { search, state } = useLocation()

    const membershipExpirationDate = state?.membershipExpirationDate
    const membershipNumber = state?.membershipNumber

    if (membershipExpirationDate && membershipNumber) {
      return (
        <Redirect
          to={{
            pathname: makeMemberPortalPath(PATH.REGISTRATION, PATH.NEW_MEMBER),
            state,
          }}
        />
      )
    }

    return (
      <Redirect
        to={{
          search,
          pathname: makeMemberPortalPath(
            PATH.REGISTRATION,
            PATH.EXISTING_MEMBER,
          ),
          state,
        }}
      />
    )
  }

  return (
    <AccountProvider accountId={memberAccountId}>{children}</AccountProvider>
  )
}

MemberAccountProvider.propTypes = exact(propTypes)

export default pure(MemberAccountProvider)
