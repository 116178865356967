import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState, setState } from 'lp-redux-utils'
import { handleSuccess, setOnSuccess } from 'lp-redux-api'
import { compose } from 'recompose'
import { set, unset, first } from 'lodash/fp'
import * as LS from 'local-storage'
import * as actions from './global-member-actions'
import * as apiActions from 'api-actions'
import { createSelector } from 'reselect'
import {
  PRODUCT_PRICE_TYPE,
  HEROKU_ID_KEY,
  MEMBERSHIP_TYPE,
  TRAILER_CARE_TYPE,
  TRAILER_CARE_TYPE_NONE,
  TRAILER_CARE_TYPE_NO_UPGRADE,
} from 'types'
import { FEATURES } from 'config'
import {
  getTrailerCareByMembershipType,
  mapToAppliedDiscountCodeType,
  mapReferralInfo,
  isFeatureEnabled,
} from 'utils'

const reducerKey = 'global'
const slice = 'root.memberPortal.global'
const initialState = {
  activationCode: LS.getMemberApplicationActivationCode(),
  applicationAccountId: LS.getMemberApplicationAccountId(),
  applicationMembershipId: LS.getMemberApplicationMembershipId(),
  gclid: LS.getMemberApplicationGCLid(),
  isTrialMembershipsActive: isFeatureEnabled(FEATURES.TRIAL_MEMBERSHIPS),
  promoCode: LS.getMemberApplicationPromoCode(),
  referralInfo: LS.getMemberApplicationReferralInfo(),
  resetShoppingCart: false,
  selectedMembershipType: LS.getSelectedMembershipType(),
  selectedTrailerCareType: resolveInitialTrailerCareType(),
  testVariant: LS.getMemberApplicationTestVariant(),
  trialMembershipType: LS.getTrialMembershipType(),
}

const reducer = handleActions(
  {
    // Product Prices
    [apiActions.fetchProductPrices]: handleSuccess(
      (state, { payload: { data } }) => {
        return compose(
          set('membershipPrices', data[PRODUCT_PRICE_TYPE.MEMBERSHIP]),
          set('trailerCarePrices', data[PRODUCT_PRICE_TYPE.TRAILER_CARE]),
        )(state)
      },
    ),
    [actions.clearMembershipPrices]: unsetState('membershipPrices'),
    [actions.clearTrailerCarePrices]: unsetState('trailerCarePrices'),

    // *TODO: Using Test Variant as placeholder until we hear back from the
    // Sea Tow Marketing Team what the terminology should be.
    // Test Variant
    [actions.setTestVariant]: (state, { payload: testVariantId }) => {
      LS.setMemberApplicationTestVariant(testVariantId)
      return set('testVariant', testVariantId, state)
    },
    [actions.clearTestVariant]: unsetState('testVariant'),
    [actions.clearTestVariantLS]: (state) => {
      LS.clearMemberApplicationTestVariant()
      return state
    },

    // Referral Info
    [apiActions.fetchReferral]: handleSuccess(setReferralInfoLSandState),
    [actions.clearReferralInfo]: (state) => {
      LS.clearMemberApplicationReferralInfo()
      return compose(
        unset('referralInfo'),
        unset('referredByMemberNumber'),
      )(state)
    },

    // Discount Codes
    [apiActions.fetchActivationCodeDetails]: handleSuccess(
      setDiscountCodeDetailsState,
    ),
    [apiActions.fetchPromoCodeDetails]: handleSuccess(
      setDiscountCodeDetailsState,
    ),
    [actions.setActivationCode]: (state, { payload: activationCode }) => {
      LS.setMemberApplicationActivationCode(activationCode)
      return set('activationCode', activationCode, state)
    },
    [actions.setPromoCode]: (state, { payload: promoCode }) => {
      LS.setMemberApplicationPromoCode(promoCode)
      return set('promoCode', promoCode, state)
    },
    [actions.clearDiscountCodeState]: (state) => {
      return compose(
        unset('activationCode'),
        unset('promoCode'),
        unset('discountCodeDetails'),
      )(state)
    },
    [actions.clearDiscountCodeLS]: (state) => {
      LS.clearMemberApplicationActivationCode()
      LS.clearMemberApplicationPromoCode()
      return state
    },
    [actions.clearMembershipDiscountDetails]: (state) => {
      if (!state.membership) return state

      const membershipState = {
        ...state.membership,
        renewal_promotion_details: null,
      }
      return set('membership', membershipState, state)
    },

    // Google Ads
    [actions.setGCLid]: (state, { payload: gclid }) => {
      LS.setMemberApplicationGCLid(gclid)
      return set('gclid', gclid, state)
    },
    [actions.clearGCLid]: unsetState('gclid'),
    [actions.clearGCLidLS]: (state) => {
      LS.clearMemberApplicationGCLid()
      return state
    },

    // Bing Ads
    [actions.clearMSClickIdLS]: (state) => {
      LS.clearMemberApplicationMSClickId()
      return state
    },

    // Membership Selections
    [actions.setSelectedMembershipType]: setMembershipTypeState,
    [actions.setTrialMembershipType]: setTrialMembershipTypeState,
    [actions.setSelectedTrailerCareType]: setTrailerCareTypeState,
    [actions.clearMembershipSelectionsState]: (state) => {
      return compose(
        unset('selectedMembershipType'),
        unset('selectedTrailerCareType'),
        unset('trialMembershipType'),
      )(state)
    },
    [actions.clearMembershipSelectionsLS]: (state) => {
      LS.clearSelectedMembershipType()
      LS.clearSelectedTrailerCareType()
      LS.clearTrialMembershipType()
      return state
    },
    // Initialize Member/Account/Contact
    [apiActions.fetchMembershipApplicationAccount]: handleSuccess(
      setApplicationAccountState,
    ),
    [apiActions.createMembershipApplicationAccount]: handleSuccess(
      setApplicationAccountState,
    ),
    [apiActions.updateMembershipApplicationAccount]: handleSuccess(
      setApplicationAccountState,
    ),
    [apiActions.updateMembershipAssociatedAccount]:
      setState('accountAssociated'),
    [actions.setApplicationAccountId]: setApplicationAccountIdState,
    [actions.setApplicationMembershipId]: setApplicationMembershipIdState,
    [actions.setMembership]: setState('membership'),
    [actions.clearAccount]: unsetState('account'),
    [actions.clearContact]: unsetState('contact'),
    [actions.clearMembership]: unsetState('membership'),
    [actions.clearApplicationAccountId]: unsetState('applicationAccountId'),
    [actions.clearApplicationMembershipId]: unsetState(
      'applicationMembershipId',
    ),
    [actions.clearApplicationAccountLS]: (state) => {
      LS.clearMemberApplicationAccountId()
      LS.clearMemberApplicationMembershipId()
      return state
    },
    // Payment Step
    [actions.setBillingOptions]: setState('billing'),
    [actions.setReferredByMemberNumber]: setState('referredByMemberNumber'),
    [actions.clearBillingOptions]: unsetState('billing'),
    // Review Step
    [apiActions.addPaymentToOpportunity]: handleSuccess(
      (
        state,
        {
          payload: {
            data: { memberships },
          },
        },
      ) => {
        const membership = first(memberships)
        return compose(
          unset('selectedMembershipType'),
          unset('trialMembershipType'),
          unset('selectedTrailerCareType'),
          unset('oppGuid'),
          set('membership', membership),
        )(state)
      },
    ),
    [apiActions.prepareOpportunityAndOLIs]: handleSuccess(
      (
        state,
        {
          payload: {
            data: { opp_guid },
          },
        },
      ) => {
        return set('oppGuid', opp_guid, state)
      },
    ),
    // Shopping Cart Reset
    [actions.setResetShoppingCart]: setState('resetShoppingCart'),

    // Video Content
    [actions.setVideoDetails]: (state, { payload }) => {
      return set('videoDetails', payload, state)
    },
  },
  initialState,
)

const select = selectorForSlice(slice)

const selectors = {
  account: select('account'),
  oppGuid: select('oppGuid'),
  activationCode: select('activationCode'),
  applicationAccountId: select('applicationAccountId'),
  applicationMembershipId: select('applicationMembershipId'),
  billing: select('billing'),
  contact: select('contact'),
  discountCodeDetails: select('discountCodeDetails'),
  gclid: select('gclid'),
  isTrialMembershipsActive: select('isTrialMembershipsActive'),
  membership: select('membership'),
  membershipPrices: select('membershipPrices'),
  promoCode: select('promoCode'),
  referralInfo: select('referralInfo'),
  referredByMemberNumber: select('referredByMemberNumber'),
  resetShoppingCart: select('resetShoppingCart'),
  selectedMembershipType: select('selectedMembershipType'),
  selectedTrailerCareType: select('selectedTrailerCareType'),
  testVariant: select('testVariant'), // *TODO: Update once decided by STSI
  trailerCarePrices: select('trailerCarePrices'),
  trialMembershipType: select('trialMembershipType'),
  videoDetails: select('videoDetails'),
}

// Computed Selectors
selectors.lakeGoldPriceDifference = createSelector(
  [selectors.membershipPrices],
  (membershipPrices) => {
    if (!membershipPrices) return 0
    return (
      membershipPrices[MEMBERSHIP_TYPE.GOLD_CARD] -
      membershipPrices[MEMBERSHIP_TYPE.LAKE_CARD]
    )
  },
)

selectors.isRecreationalCard = createSelector(
  [selectors.selectedMembershipType],
  (selectedMembershipType) => {
    if (!selectedMembershipType) return
    return (
      selectedMembershipType === MEMBERSHIP_TYPE.GOLD_CARD ||
      selectedMembershipType === MEMBERSHIP_TYPE.LAKE_CARD
    )
  },
)

selectors.promoCodeIsTrial = createSelector(
  [selectors.selectedMembershipType],
  (selectedMembershipType) => {
    if (!selectedMembershipType) return false
    return selectedMembershipType === MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP
  },
)

selectors.shoppingCartMembershipType = createSelector(
  [selectors.selectedMembershipType, selectors.trialMembershipType],
  (selectedMembershipType, trialMembershipType) => {
    if (!selectedMembershipType) return
    return selectedMembershipType === MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP
      ? trialMembershipType
      : selectedMembershipType
  },
)

selectors.trialDuration = createSelector(
  [selectors.discountCodeDetails],
  (discountCodeDetails) => {
    if (!discountCodeDetails) return
    return discountCodeDetails?.related_product?.value_time_in_days__c
  },
)

selectors.normalizedReferralNumber = createSelector(
  [selectors.referralInfo, selectors.referredByMemberNumber],
  (referralInfo, referredByMemberNumber) => {
    if (!referralInfo && !referredByMemberNumber) return
    return referredByMemberNumber ?? referralInfo?.fetchedReferrerMemberNumber
  },
)

selectors.isReferralMatch = createSelector(
  [selectors.referralInfo, selectors.referredByMemberNumber],
  (referralInfo, referredByMemberNumber) => {
    if (!referralInfo) return
    return referralInfo?.fetchedReferrerMemberNumber === referredByMemberNumber
  },
)

function setApplicationAccountState(state, { payload: { data } }) {
  // make sure LS and state have the correct account and membership IDs
  const stateWithIds = setApplicationIdsState(state, { data })
  // make sure LS and state have the most up to date product selections
  const stateWithProductSelections = setProductSelectionsState(stateWithIds, {
    data,
  })

  return compose(
    set('account', data.account),
    set('contact', data.contact),
    set('membership', data.membership__c),
  )(stateWithProductSelections)
}

function setApplicationAccountIdState(state, { payload: accountId }) {
  LS.setMemberApplicationAccountId(accountId)
  return set('applicationAccountId', accountId, state)
}

function setApplicationMembershipIdState(state, { payload: membershipId }) {
  LS.setMemberApplicationMembershipId(membershipId)
  return set('applicationMembershipId', membershipId, state)
}

function setApplicationIdsState(state, { data }) {
  const stateWithAccountId = setApplicationAccountIdState(state, {
    payload: data.account[HEROKU_ID_KEY],
  })

  return setApplicationMembershipIdState(stateWithAccountId, {
    payload: data.membership__c[HEROKU_ID_KEY],
  })
}

function setProductSelectionsState(state, { data }) {
  // To maintain the correct membership type selection, first check local
  // storage if selected-membership-type exists. If it exists, then we can set
  // this as the membership type value. If it does not exist, then check the
  // API's shopping cart value. If the shopping cart value exists AND trial-
  // membership-type is not in local storage, we can set the shopping cart value
  // as the membership type. If the cart value exists AND trial-membership-type
  // also exists in local storage, then can set the membership type as Trial
  // Membership. Otherwise, fallback to the membership type that's saved in
  // the membership object (which will only be set during a renewal)
  let membershipType
  const lsSelectedMembershipType = LS.getSelectedMembershipType()
  const lsTrialMembershipType = LS.getTrialMembershipType()
  const shoppingCartMembershipType =
    data.membership__c.shopping_cart_membership_type__c

  if (lsSelectedMembershipType) {
    membershipType = lsSelectedMembershipType
  } else if (shoppingCartMembershipType && !lsTrialMembershipType) {
    membershipType = shoppingCartMembershipType
  } else if (shoppingCartMembershipType && lsTrialMembershipType) {
    membershipType = MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP
  } else {
    membershipType = data.membership__c.membership_type__c
  }

  const trailerCareType =
    LS.getSelectedTrailerCareType() ??
    data.membership__c.shopping_cart_trailer_care_type__c ??
    data.membership__c.trailer_care_type__c ??
    TRAILER_CARE_TYPE.PLUS

  const stateWithMembershipType = setMembershipTypeState(state, {
    payload: membershipType,
  })

  return setTrailerCareTypeState(stateWithMembershipType, {
    payload: trailerCareType,
  })
}

function setMembershipTypeState(state, { payload: membershipType }) {
  const trailerCareType = getTrailerCareByMembershipType(membershipType)

  LS.setSelectedMembershipType(membershipType)
  LS.setSelectedTrailerCareType(trailerCareType)

  return compose(
    set('selectedMembershipType', membershipType),
    set('selectedTrailerCareType', trailerCareType),
  )(state)
}

function setTrialMembershipTypeState(state, { payload: trialType }) {
  // If the Membership Type is NOT Trial Membership, the Trial Membership
  // Duration should never exist. Clear it from LS and set to null in
  // the Redux store
  const lsSelectedMembershipType = LS.getSelectedMembershipType()

  if (lsSelectedMembershipType !== MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP) {
    LS.clearTrialMembershipType()
    return set('trialMembershipType', null, state)
  }

  LS.setTrialMembershipType(trialType)
  return compose(
    set('trialMembershipType', trialType),
    set('selectedTrailerCareType', TRAILER_CARE_TYPE_NO_UPGRADE),
  )(state)
}

function setTrailerCareTypeState(state, { payload: trailerCareType }) {
  const lsSelectedMembershipType = LS.getSelectedMembershipType()
  
  const calculatedTrailerCareType = getTrailerCareByMembershipType(
    lsSelectedMembershipType,
    trailerCareType,
  )

  LS.setSelectedTrailerCareType(calculatedTrailerCareType)
  return set('selectedTrailerCareType', calculatedTrailerCareType, state)
}

function setDiscountCodeDetailsState(state, { payload: { data } }) {
  return set('discountCodeDetails', mapToAppliedDiscountCodeType(data), state)
}

function setReferralInfoLSandState(state, { payload: { data } }) {
  const mappedReferralData = mapReferralInfo(data)

  LS.setMemberApplicationReferralInfo(mappedReferralData)
  return set('referralInfo', mappedReferralData, state)
}

function resolveInitialTrailerCareType() {
  const trailerCareType = LS.getSelectedTrailerCareType()

  if (trailerCareType?.toLowerCase() === TRAILER_CARE_TYPE_NONE.toLowerCase()) {
    LS.setSelectedTrailerCareType(TRAILER_CARE_TYPE_NO_UPGRADE)
    return TRAILER_CARE_TYPE_NO_UPGRADE
  }

  return trailerCareType || TRAILER_CARE_TYPE.PLUS
}

export { reducer, selectors, reducerKey }
