import { v4 as uuidv4 } from 'uuid'
import { api } from 'api'
import { makeLoginPath, PATH } from 'config'
import { setAuthNonce } from './authStorage'

const generateState = () => uuidv4().replace(/-/g, '').slice(0, 16)
const generateCodeVerifier = () => uuidv4().replace(/-/g, '').slice(0, 43)

const generateCodeChallenge = async (codeVerifier) => {
	const data = new TextEncoder().encode(codeVerifier)
	const hashBuffer = await crypto.subtle.digest('SHA-256', data)
	const hashArray = Array.from(new Uint8Array(hashBuffer))

	return hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')
}

const generateNonce = (state, codeVerifier, codeChallenge, returnTo) => {
	return {
		[state]: {
			code_verifier: codeVerifier,
			code_challenge: codeChallenge,
			returnTo: returnTo,
		},
	}
}

const login = async (params) => {
	const state = generateState()
	const codeVerifier = generateCodeVerifier()
	const codeChallenge = await generateCodeChallenge(codeVerifier)
	const returnToPath = params?.appState?.returnTo || window.location.pathname
	const redirectUri = `${window.location.origin}${PATH.POST_LOGIN}`
	const nonce = generateNonce(state, codeVerifier, codeChallenge, returnToPath)

	setAuthNonce(nonce)

	const membershipNumber = params?.appState?.membershipNumber || ''
	const expDate = params?.appState?.membershipExpirationDate || ''
	const prompt = params?.authorizationParams?.screen_hint || ''

	const { redirect_uri } = await api.post(makeLoginPath(PATH.AUTHORIZE), {
		redirectUri,
		authState: state,
		codeChallenge,
		membershipNumber,
		expDate,
		prompt,
	})

	window.location.href = redirect_uri
}

export default login
