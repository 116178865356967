import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAuth } from 'components/auth'
import { Spinner } from 'lp-components'
import { ConfirmContextProvider, ConfirmModalProvider } from 'components'
import { Routes as ErrorRoutes } from './error'
import { App as MemberPortalApp } from './member-portal'
import { App as ProgramsPortalApp } from './programs-portal'
import { App as SeaTowApp } from './sea-tow'
import { Routes as StyleguideRoutes } from './styleguide'
import { PATH } from 'config'

const propTypes = {}

function Routes() {
  const { isLoading, error } = useAuth()

  if (error) return <Redirect to={PATH.SERVER_ERROR} />
  if (isLoading) return <Spinner />

  return (
    <ConfirmContextProvider>
      <ConfirmModalProvider>
        <Switch>
          <Route path={PATH.ERROR}>
            <ErrorRoutes />
          </Route>
          <Route path={PATH.MEMBER_PORTAL}>
            <MemberPortalApp />
          </Route>
          <Route path={PATH.PROGRAMS_PORTAL}>
            <ProgramsPortalApp />
          </Route>
          <Route path={PATH.STYLEGUIDE}>
            <StyleguideRoutes />
          </Route>

          {/* Default all other routes to Sea Tow.com... */}
          <Route>
            <SeaTowApp />
          </Route>
        </Switch>
      </ConfirmModalProvider>
    </ConfirmContextProvider>
  )
}

Routes.propTypes = propTypes

export default Routes
