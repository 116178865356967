import React from 'react'
import Routes from './Routes'
import '../../scss/application.scss'
import history from 'browser-history'
import store from 'store'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import {
  APPLICATION_ROOT_URL,
  PATH,
  REACT_APP_SEATOW_AUTH0_CLIENTID,
  REACT_APP_SEATOW_API,
  REACT_APP_AUTH0_DOMAIN,
  makePath,
} from 'config'

// Log runtime errors with sentry service
import 'sentry'
import AuthProvider from 'components/auth/AuthProvider'

function App() {
  return (
    <Auth0Provider
      authorizationParams={{
        audience: REACT_APP_SEATOW_API,
        redirect_uri: makePath(APPLICATION_ROOT_URL, PATH.POST_LOGIN),
      }}
      clientId={REACT_APP_SEATOW_AUTH0_CLIENTID}
      domain={REACT_APP_AUTH0_DOMAIN}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </ConnectedRouter>
      </Provider>
    </Auth0Provider>
  )
}

export default App

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname, appState)
}
