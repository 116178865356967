import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFastApi } from 'fastApi'
import { Stream } from '@cloudflare/stream-react'
import { Spinner } from 'lp-components'
import { noop } from 'lodash'
import { isTokenExpired } from 'utils'

const yellowBase = '#FDDC2F'

const propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	isPrivate: PropTypes.bool.isRequired,
	src: PropTypes.string.isRequired,
	imgLink: PropTypes.string,
	setErrorType: PropTypes.func.isRequired,
}

const VideoPlayer = ({
	isDisabled,
	isPrivate,
	src,
	imgLink = '',
	setErrorType,
}) => {
	const [signedToken, setSignedToken] = useState(null)
	const [isVideoReady, setIsVideoReady] = useState(false)

	const api = useFastApi()

	useEffect(() => {
		if (!isPrivate) return

		const fetchEncryptedUID = async () => {
			try {
				const {
					result: { token },
				} = await api.get('/cloudflare/stream/token', {
					query: { video_uid: src },
				})
				setSignedToken(token)
			} catch {
				setErrorType('error')
			}
		}

		fetchEncryptedUID()
	}, [isPrivate, src])

	const handleTokenValidation = () => {
		if (isTokenExpired(signedToken)) {
			setErrorType('expired')
		}
	}

	if (isPrivate && !signedToken) {
		return (
			<div className="video-player-container">
				<div className="is-loading">
					<Spinner />
				</div>
			</div>
		)
	}

	return (
		<div className="video-player-container">
			<Stream
				autoplay={!(isPrivate && isDisabled)}
				controls={!(isPrivate && isDisabled)}
				src={signedToken ?? src}
				poster={imgLink}
				primaryColor={yellowBase}
				onPlay={isPrivate ? handleTokenValidation : noop}
				onCanPlay={() => setIsVideoReady(true)}
				onError={() => setErrorType('error')}
			/>
			{!isVideoReady && <div className="is-loading" />}
		</div>
	)
}

VideoPlayer.propTypes = propTypes

export default VideoPlayer
