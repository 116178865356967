import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import useAuth from './auth/useAuth'
import OutsideClickHandler from 'react-outside-click-handler'
import { isEmpty } from 'lodash'
import { useKey } from 'react-use'
import { Button } from 'lp-components'
import Logo from './Logo'
import LinkButton from './LinkButton'
import { ContactType, MenuItemType } from 'types'
import { makeMarketingSitePath, makePath, PATH, SEATOW_PHONE } from 'config'

const propTypes = {
  applicationLinks: PropTypes.arrayOf(MenuItemType),
  clearShoppingCart: PropTypes.func.isRequired,
  contactLink: PropTypes.string,
  logoLink: PropTypes.string,
  primaryContact: ContactType,
  videoDetails: PropTypes.object,
}

function Navigation({
  applicationLinks,
  clearShoppingCart,
  contactLink = makePath(PATH.SEATOW, PATH.CONTACT),
  logoLink,
  primaryContact,
  videoDetails,
}) {
  const isGatedContent = !isEmpty(videoDetails)

  return (
    <nav
      className={classnames('page-navigation', {
        'no-bottom-border': isGatedContent,
      })}
    >
      <div className="page-navigation-block-container">
        <div className="inner">
          <Logo {...{ logoLink }} />
          <NavigationLinks
            {...{
              applicationLinks,
              clearShoppingCart,
              contactLink,
              primaryContact,
            }}
          />
        </div>
      </div>
    </nav>
  )
}

Navigation.propTypes = propTypes

function mapStateToProps(state) {
  return {
    videoDetails: selectors.videoDetails(state),
  }
}

const mapDispatchToProps = {
  clearShoppingCart: memberActions.clearShoppingCart,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Navigation)

function NavigationLinks({
  applicationLinks,
  clearShoppingCart,
  contactLink,
  primaryContact,
}) {
  return (
    <ul className="navigation-links">
      <li>
        <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a>
      </li>
      <li>
        <a href={contactLink}>Contact</a>
      </li>
      {applicationLinks && applicationLinks.length > 0 && (
        <li className="account-link">
          <AccountMenu
            {...{ applicationLinks, clearShoppingCart, primaryContact }}
          />
        </li>
      )}
    </ul>
  )
}

const initialsFromName = (firstname, lastname) => {
  const firstInitial = firstname?.charAt(0) || ''
  const lastInitial = lastname?.charAt(0) || ''

  return [firstInitial, lastInitial].filter(Boolean).join('')
}

function AccountMenu({ applicationLinks, clearShoppingCart, primaryContact }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const { firstname, lastname, email } = primaryContact
  const toggleMenu = () => setMenuOpen(!menuOpen)
  const closeMenu = () => setMenuOpen(false)
  const fullname = [firstname, lastname].filter(Boolean).join(' ')
  const { logout } = useAuth()
  const contactInitials = initialsFromName(firstname, lastname)

  useKey('Escape', closeMenu)

  return (
    <OutsideClickHandler
      disabled={menuOpen === false}
      onOutsideClick={closeMenu}
    >
      <Button onClick={toggleMenu}>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Profile.svg"
          alt=""
          className="navigation-icon"
        />
        <span>{fullname}</span>
        <img
          src="/assets/images/light-dropdown.svg"
          alt=""
          className="dropdown"
        />
      </Button>

      <div
        className={classnames('account-block', { ['is-open']: menuOpen })}
        onClick={closeMenu}
      >
        <div className="account-block-inner">
          <div className="user">
            {contactInitials && (
              <span className="initials">{contactInitials}</span>
            )}
            <p>{fullname}</p>
            <p>{email}</p>
          </div>
          <ul className="account-links">
            {applicationLinks.map(({ icon, menuItemName, path }) => (
              <li key={menuItemName}>
                <Link to={path}>
                  <img alt="" className="icon" src={icon} />
                  {menuItemName}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <LinkButton
          onClick={() => {
            clearShoppingCart()
            logout({
              logoutParams: {
                returnTo: makeMarketingSitePath(PATH.SEATOW_LOGOUT),
              },
            })
          }}
          className="link-primary"
        >
          Logout
        </LinkButton>
      </div>
    </OutsideClickHandler>
  )
}
