import { useEffect } from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useHistory, useLocation } from 'react-router-dom'
import login from 'components/auth/login'
import useAuth from 'components/auth/useAuth'
import { getAuthNonce, removeAuthNonce } from 'components/auth/authStorage'
import { isEmpty } from 'lodash'
import { api } from 'api'
import { makeLoginPath, MEMBER_PORTAL_URL, PATH } from 'config'
import { parseQuery } from 'utils'

const propTypes = {}

function PostLogin() {
  const history = useHistory()
  const { search } = useLocation()
  const { code, state } = parseQuery(search)
  const { shouldUseCustomAuth, setIsAuthenticated, setUser } = useAuth()

  useEffect(() => {
    if (!shouldUseCustomAuth) return

    const handleStateValidation = async () => {
      const nonce = getAuthNonce()

      if (isEmpty(nonce)) {
        return login(MEMBER_PORTAL_URL)
      }

      const { code_verifier, returnTo } = nonce[state]

      await api.post(makeLoginPath(PATH.CODE, PATH.EXCHANGE), {
        authCode: code,
        authState: state,
        codeVerifier: code_verifier,
      })

      const { claims } = await api.get(
        makeLoginPath(PATH.SESSION, PATH.AUTHORIZATION),
      )

      removeAuthNonce()
      setIsAuthenticated(true)
      setUser(claims)
      history.push(returnTo)
    }

    handleStateValidation()
  }, [shouldUseCustomAuth, code, state, setIsAuthenticated, history])

  return null
}

PostLogin.propTypes = exact(propTypes)

export default pure(PostLogin)
