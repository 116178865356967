import { configureApi, http } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
import { isNil } from 'lodash'
import { API_ERROR_MAP, ERROR_STATUS } from 'types'
import { PATH, REACT_APP_API_REQUEST_TOKEN } from 'config'
import { handleClientError, handleServerError } from 'utils'
// Configure lp-redux-api middleware

// This function will be passed the request options before every request.
// You can use it to set additional options or override existing ones.
function before({ url }) {
  const requiresAuth = url.includes(PATH.LOGIN)

  return {
    headers: {
      'Request-Token': REACT_APP_API_REQUEST_TOKEN,
    },
    ...(requiresAuth && { credentials: 'include' }),
  }
}

// Any transformations of successful responses can go here.
function onSuccess(res) {
  return res
}

function onFailure(httpError) {
  const status = httpError?.status
  const invalidCookieMessage = API_ERROR_MAP.INVALID_COOKIE
  const cookieNotFoundMessage = API_ERROR_MAP.NO_COOKIE_FOUND

  if (isNil(status)) {
    return handleClientError(ERROR_STATUS.API_DOWN)
  }

  if (status === 404 && httpError.response?.message === cookieNotFoundMessage) {
    throw new Error(cookieNotFoundMessage)
  }

  if (status === 401 && httpError.response?.message === invalidCookieMessage) {
    throw new Error(invalidCookieMessage)
  }

  if ([401, 403, 404].includes(status)) {
    return handleClientError(status)
  }

  if (status >= 500 && status <= 599) {
    return handleServerError()
  }

  return httpError
}

export const config = {
  root: process.env.REACT_APP_API_URL,
  before,
  onSuccess,
  onFailure,
  mode: 'cors',

  // Turn off key (de)camelization...
  camelizeResponse: false,
  decamelizeBody: false,
  decamelizeQuery: false,
}

export const middleware = configureMiddleware(http, config)
export const api = configureApi(config)

export const wpApi = async (path) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SEA_TOW_WP_API}/${path}`,
    )

    if (response.ok) {
      const data = await response.json()
      return onSuccess(data)
    }
  } catch (error) {
    onFailure(error)
  }
}
