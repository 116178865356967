import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {
  Field,
  SubmissionError,
  propTypes as formPropTypes,
  formValues,
} from 'redux-form'
import { isEmpty } from 'lodash'
import { lpForm } from 'lp-form'
import { MobilePushNotificationLabel, SmsNotificationLabel } from 'components'
import {
  Button,
  Checkbox,
  Input,
  MaskedInput,
  Modal,
  Select,
  SubmitButton,
} from 'lp-components'
import {
  CONTACT_MEMBER_RELATIONSHIPS,
  CONTACT_PARTNER_RELATIONSHIPS,
} from 'types'
import { PHONE_REGION_CODE } from 'config'
import { parsePhoneNumber, useToggle } from 'utils'
import { Tooltip } from 'react-tooltip'

const propTypes = {
  country: PropTypes.string.isRequired,
  email: PropTypes.string,
  id: PropTypes.string.isRequired,
  isMemberContact: PropTypes.bool.isRequired,
  isPrimaryContact: PropTypes.bool.isRequired,
  isUsernamePasswordLogin: PropTypes.bool.isRequired,
  toggleContactForm: PropTypes.func,
  ...formPropTypes,
}

function ContactForm({
  change,
  country,
  email,
  handleSubmit,
  id,
  initialValues,
  isMemberContact,
  isPrimaryContact,
  isUsernamePasswordLogin,
  toggleContactForm,
}) {
  const [isModalOpen, toggleIsModalOpen] = useToggle()

  const confirmEmailChange = () => {
    toggleIsModalOpen()
    handleSubmit()
  }

  const cancelEmailChange = () => {
    change('email', initialValues.email)
    toggleIsModalOpen()
  }

  const handleContactFormSubmit = (e) => {
    e.preventDefault()
    if (isPrimaryContact && initialValues.email !== email) {
      toggleIsModalOpen()
    } else {
      handleSubmit()
    }
  }

  return (
    <>
      <form onSubmit={handleContactFormSubmit} noValidate>
        <div className="group">
          <div className="row">
            <div className="col-6">
              <Field
                component={Input}
                label="First Name"
                name="firstName"
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-6">
              <Field
                component={Input}
                label="Last Name"
                name="lastName"
                required
                requiredIndicator="*"
              />
            </div>
          </div>
          {!initialValues && (
            <div className="row">
              <div className="col-6">
                <Field
                  component={Select}
                  label={isMemberContact ? 'Relationship' : 'Role'}
                  name="relationship"
                  options={
                    isMemberContact
                      ? CONTACT_MEMBER_RELATIONSHIPS
                      : CONTACT_PARTNER_RELATIONSHIPS
                  }
                  placeholder={'Select'}
                  required
                  requiredIndicator="*"
                />
              </div>
            </div>
          )}
          {!isUsernamePasswordLogin && isPrimaryContact && (
            <Tooltip id={id} />
          )}
          <Field
            data-tooltip-id={id}
            data-tooltip-content="Manage directly with your social identity provider."
            data-tooltip-place="top-start"
            component={Input}
            disabled={!isUsernamePasswordLogin && isPrimaryContact}
            label="Email"
            name="email"
            required={isPrimaryContact}
            requiredIndicator="*"
          />
          <div className="row">
            <div className="col-6">
              <Field
                component={MaskedInput}
                label="Home Phone"
                maskOptions={{
                  numericOnly: true,
                  blocks: [0, 3, 0, 3, 4],
                  delimiters: ['(', ')', ' ', '-'],
                  phoneRegionCode: PHONE_REGION_CODE[country],
                }}
                name="phone"
                parse={parsePhoneNumber}
                placeholder="(800) 555-1212"
              />
            </div>
            <div className="col-6">
              <Field
                component={MaskedInput}
                label="Mobile Phone"
                maskOptions={{
                  numericOnly: true,
                  blocks: [0, 3, 0, 3, 4],
                  delimiters: ['(', ')', ' ', '-'],
                  phoneRegionCode: PHONE_REGION_CODE[country],
                }}
                name="mobilePhone"
                parse={parsePhoneNumber}
                placeholder="(800) 555-1212"
              />
            </div>
          </div>
        </div>
        {isMemberContact && (
          <>
            <div className="group">
              <h3>Email Preferences</h3>
              <Field
                name="seaTowNews"
                component={Checkbox}
                label="SeaTow News"
              />
              <Field
                name="benefitsAndOffers"
                component={Checkbox}
                label="Benefits And Offers"
              />
              <Field
                name="localCaptainsCorner"
                component={Checkbox}
                label="Local Captain's Corner"
              />
            </div>
            <div className="group">
              <h3>SMS Opt-In</h3>
              <Field
                name="smsOptIn"
                component={Checkbox}
                labelComponent={(props) => <SmsNotificationLabel {...props} />}
              />
            </div>
            <div className="group">
              <h3>Mobile Push Notification Opt-In</h3>
              <Field
                name="mobilePushOptIn"
                component={Checkbox}
                labelComponent={(props) => (
                  <MobilePushNotificationLabel {...props} />
                )}
              />
            </div>
          </>
        )}

        <div className="button-group">
          {initialValues && (
            <Button
              onClick={toggleContactForm}
              className="button-primary-outline"
            >
              Cancel
            </Button>
          )}
          <SubmitButton>
            {initialValues ? 'Update Contact' : 'Submit'}
          </SubmitButton>
        </div>
      </form>

      {isModalOpen && (
        <Modal preventClose={true}>
          <p>
            Updating your email also means updating your login credentials. If
            you proceed, you will be logged out and need to sign in with your
            new email.
          </p>
          <div className="button-group">
            <Button
              onClick={cancelEmailChange}
              className="button-primary-outline"
            >
              Cancel
            </Button>
            <Button onClick={confirmEmailChange}>Update & Sign Out</Button>
          </div>
        </Modal>
      )}
    </>
  )
}

const beforeSubmit = (contactFormData, { initialValues, isPrimaryContact }) => {
  if (!initialValues) {
    const relationship = contactFormData['relationship']
    if (!relationship) {
      throw new SubmissionError({
        relationship: 'Relationship must be specified',
      })
    }
  }

  if (isPrimaryContact) {
    const { email, phone, mobilePhone, smsOptIn } = contactFormData

    if (isEmpty(email)) {
      throw new SubmissionError({
        email: 'Email must be specified for the primary contact',
      })
    }
    if (isEmpty(phone) && isEmpty(mobilePhone)) {
      throw new SubmissionError({
        phone:
          'either Home Phone or Mobile Phone must be specfied for the primary contact',
      })
    }

    if (smsOptIn && !mobilePhone) {
      throw new SubmissionError({
        smsOptIn:
          'SMS Opt-In cannot be checked without providing a Mobile Phone number',
      })
    }
  }

  return contactFormData
}

ContactForm.propTypes = propTypes

export default compose(
  lpForm({
    beforeSubmit,
    constraints: {
      firstName: { presence: true },
      lastName: { presence: true },
    },
  }),
  formValues('email'),
)(ContactForm)
