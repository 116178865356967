import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button, Modal } from 'lp-components'
import { makeMembershipIdPath, PATH } from 'config'

const propTypes = {
  handleCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  sfid: PropTypes.string,
}

function ExpiredMembershipWarningModal({ handleCancel, isOpen, sfid = '' }) {
  const history = useHistory()

  return (
    <>
      {isOpen && (
        <Modal preventClose={true}>
          <p>
            This page you are trying to view is for current members only. Click
            continue to renew your member benefits.
          </p>

          <div className="button-group">
            <Button onClick={handleCancel} className="button-primary-outline">
              Cancel
            </Button>
            <Button
              onClick={() => {
                history.push(
                  makeMembershipIdPath(
                    sfid,
                    PATH.RENEW_MEMBERSHIP,
                    PATH.SELECTION,
                  ),
                )
              }}
              className="button-primary"
            >
              Continue
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

ExpiredMembershipWarningModal.propTypes = propTypes

export default ExpiredMembershipWarningModal
