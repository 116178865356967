import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from 'member-reducer'
import { selectors as membershipsSelector } from 'memberships/reducer'
import { Button } from 'lp-components'
import { AllMembershipsType, MEMBERSHIP_STATUS } from 'types'
import { VideoErrorMessage, VideoPlayer } from 'member-portal/components'
import { makePath, PATH } from 'config'
import { getPrimaryMembership } from 'utils'

const propTypes = {
  allMemberships: AllMembershipsType,
  videoDetails: PropTypes.object,
}

function VideoContent({ allMemberships, videoDetails }) {
  const [errorType, setErrorType] = useState(null)

  const { membershipStatus } = getPrimaryMembership(allMemberships)
  const isDisabled = membershipStatus === MEMBERSHIP_STATUS.EXPIRED

  const {
    is_private,
    video_uid: UID,
    categories,
    title,
    imgLink,
    long_description,
  } = videoDetails

  if (errorType) {
    return (
      <section className="form-block-container">
        <VideoErrorMessage type={errorType} />
      </section>
    )
  }

  return (
    <>
      <div className="top-block" />
      <section className="form-block-container">
        <div className="categories">
          {categories.map((category) => (
            <span key={category}>{category}</span>
          ))}
        </div>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <VideoPlayer
          isDisabled={isDisabled}
          isPrivate={is_private}
          imgLink={imgLink}
          src={UID}
          setErrorType={setErrorType}
        />
        <div dangerouslySetInnerHTML={{ __html: long_description }} />
        <Button
          className="button-primary-outline"
          onClick={() =>
            window.location.replace(makePath(PATH.SEATOW, PATH.VIDEOS))
          }
        >
          Back to Videos
        </Button>
      </section>
    </>
  )
}

VideoContent.propTypes = propTypes

function mapStateToProps(state) {
  return {
    allMemberships: membershipsSelector.allMemberships(state),
    videoDetails: selectors.videoDetails(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  VideoContent,
)
