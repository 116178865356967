import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from 'member-reducer'
import { Button } from 'lp-components'

const videoErrorConfig = {
  expired: {
    title: 'Session Expired',
    message:
      'It seems your session for the video has expired due to inactivity. To regain access, simply click the button below.',
  },
  error: {
    title: 'Video Error',
    message:
      'It seems there was an error loading the video. Click the button below to refresh the video or try again later.',
  },
}

const propTypes = {
  type: PropTypes.string.isRequired,
}

function VideoErrorMessage({type}) {
  const { title, message } = videoErrorConfig[type]

  return (
      <div className="card centered card-small error">
        <div className="card-inner">
          <img src="/assets/images/video-refresh.svg" alt="" />
          <h2>{title}</h2>
          <p>{message}</p>
          <Button
            className="button-primary"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </div>
      </div>
  )
}

VideoErrorMessage.propTypes = propTypes

function mapStateToProps(state) {
  return {
    videoDetails: selectors.videoDetails(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  VideoErrorMessage,
)
