/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useLocation } from 'react-router-dom'
import { Spinner } from 'lp-components'
import useAuth from './auth/useAuth'

const propTypes = {
  children: PropTypes.node.isRequired,
}

function AuthenticatedRoutes({ children }) {
  const { isAuthenticated, isLoading, login } = useAuth()
  const { pathname, search } = useLocation()

  if (isLoading) {
    return <Spinner />
  }

  if (isAuthenticated) {
    return children
  }

  login({
    appState: {
      returnTo: `${pathname}${search}`,
    },
  })
}

AuthenticatedRoutes.propTypes = exact(propTypes)

export default AuthenticatedRoutes
