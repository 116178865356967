import { useEffect } from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import useAuth from 'components/auth/useAuth'
import { PATH, makeMarketingSitePath } from 'config'

const propTypes = {}

function Logout() {
  const { logout } = useAuth()

  useEffect(
    () =>
      logout({
        logoutParams: { returnTo: makeMarketingSitePath(PATH.SEATOW_LOGOUT) },
      }),
    [],
  )

  return null
}

Logout.propTypes = exact(propTypes)

export default pure(Logout)
