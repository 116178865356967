import React, { createContext } from 'react'

const AuthContext = createContext({
	login: () => {},
	logout: () => {},
	user: null,
	isAuthenticated: false,
	shouldUseCustomAuth: false,
	isLoading: false,
})

export default AuthContext
