import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Button, Input, MaskedInput, SubmitButton } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { formatISODateString } from 'utils'

const propTypes = {
  cancel: PropTypes.func.isRequired,
  joinHandler: PropTypes.func.isRequired,
  ...formPropTypes,
}

function RegistrationForm({
  cancel,
  joinHandler,
  handleSubmit,
  pristine,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        component={Input}
        label="Membership Number"
        name="membershipNumber"
        type="text"
        required
      />
      <Field
        component={MaskedInput}
        label="Membership Expiration Date"
        maskOptions={{
          date: true,
          datePattern: ['m', 'd', 'Y'],
        }}
        name="membershipExpirationDate"
        placeholder="MM/DD/YYYY"
        type="text"
        required
      />
      <SubmitButton
        {...{ pristine, submitting }}
        className="button-tertiary button-large"
      >
        Submit
      </SubmitButton>
      <Button onClick={joinHandler} className="link-secondary">
        Not a member yet? Click here to join!
      </Button>
      <Button onClick={cancel} className="link-secondary">
        Go Back
      </Button>
    </form>
  )
}

RegistrationForm.propTypes = propTypes

const beforeSubmit = (registrationValues) => {
  // Reformat the date to YYYY-MM-DD
  const { membershipExpirationDate } = registrationValues
  const formattedExpiration = formatISODateString(membershipExpirationDate)

  return {
    ...registrationValues,
    membershipExpirationDate: formattedExpiration,
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'RegistrationForm',
    constraints: {
      membershipNumber: { presence: { allowEmpty: false } },
      membershipExpirationDate: {
        length: { is: 10 },
        presence: true,
      },
    },
  }),
)(RegistrationForm)
